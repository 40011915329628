import { KIZUNA_DESIGNS } from "components/Designs/constants";
import { FormattedMessage } from "react-intl";
import FeaturesAndBenefitsElements from "../components/FeaturesAndBenefitsElements";
import ApplicationsElements from "../components/ApplicationsElements";

const COMPOSITION = "COMPOSITION";
const FEATURES_AND_BENEFITS = "FEATURES_AND_BENEFITS";
const APPLICATIONS = "APPLICATIONS";
const FINISHES_AND_TREATMENTS = "FINISHES_AND_TREATMENTS";
const FLAME_RETARDANCY = "FLAME_RETARDANCY";
const ADDITIONAL_TEST = "ADDITIONAL_TEST";
const WARRANTY = "WARRANTY";
const DIGITAL_CATALOGUE = "DIGITAL_CATALOGUE";
const CARE_AND_CLEANING = "CARE_AND_CLEANING";

export const PRODUCT_SPECS = {
  [COMPOSITION]: (
    <FormattedMessage
      id="Kizuna.specComposition"
      defaultMessage="Composition"
    />
  ),
  [FEATURES_AND_BENEFITS]: (
    <FormattedMessage
      id="Kizuna.specFeaturesBenefits"
      defaultMessage="Features & Benefits"
    />
  ),
  [APPLICATIONS]: (
    <FormattedMessage
      id="Kizuna.specApplications"
      defaultMessage="Applications"
    />
  ),
  [FINISHES_AND_TREATMENTS]: (
    <FormattedMessage
      id="Kizuna.specFinishesTreatments"
      defaultMessage="Finishes & Treatments"
    />
  ),
  [FLAME_RETARDANCY]: (
    <FormattedMessage
      id="Kizuna.specFlameRetardancy"
      defaultMessage="Flame Retardancy"
    />
  ),
  [ADDITIONAL_TEST]: (
    <FormattedMessage
      id="Kizuna.specAdditionalTest"
      defaultMessage="Additional Tests"
    />
  ),
  [WARRANTY]: (
    <FormattedMessage id="Kizuna.specWarranty" defaultMessage="Warranty" />
  ),
  [DIGITAL_CATALOGUE]: (
    <FormattedMessage
      id="Kizuna.specDigitalCatalogue"
      defaultMessage="Digital Catalogue"
    />
  ),
  [CARE_AND_CLEANING]: (
    <FormattedMessage
      id="Kizuna.specCareCleaning"
      defaultMessage="Care & Cleaning"
    />
  ),
};

const DETAIL_VALUES_ACCORDING_TO_DESIGN = {
  [KIZUNA_DESIGNS[0].toLowerCase()]: {
    weight: (
      <>
        420 g/m<sup>2</sup> | 12,4 oz/sqyd
      </>
    ),
    openessFactor: "2,5%",
    colorFatnessToDomestic: "4-5",
    pilling: "4 (B)",
    tensibleStrength: "165/190 daN/5 cm",
    elongation: "30/25 %",
    elongationUnderConstant: "< 2%",
    tearingResistance: "15/15 daN",
    seamResistance: "30/30 daN",
  },
  [KIZUNA_DESIGNS[1].toLowerCase()]: {
    weight: (
      <>
        415 g/m<sup>2</sup> | 12,2 oz/sqyd
      </>
    ),
    openessFactor: "3,0%",
    colorFatnessToDomestic: "5",
    pilling: "4-5 (A)",
    tensibleStrength: "165/185 daN/5 cm",
    elongation: "30/30 %",
    elongationUnderConstant: "< 1%",
    tearingResistance: "20/15 daN",
    seamResistance: "28/29 daN",
  },
  [KIZUNA_DESIGNS[2].toLowerCase()]: {
    weight: (
      <>
        420 g/m<sup>2</sup> | 12,4 oz/sqyd
      </>
    ),
    openessFactor: "2,5%",
    colorFatnessToDomestic: "5",
    pilling: "4 (B)",
    tensibleStrength: "165/190 daN/5 cm",
    elongation: "30/30 %",
    elongationUnderConstant: "< 1%",
    tearingResistance: "25/25 daN",
    seamResistance: "30/30 daN",
  },
};

export const productSpecDetailsInformationToShow = (design) => {
  const PRODUCT_SPECS_DETAIL_INFORMATION = {
    [COMPOSITION]: {
      info: [
        {
          id: "Kizuna.specCompositionDetailWegiht",
          description: (
            <FormattedMessage
              id="Kizuna.specCompositionDetailWegiht"
              defaultMessage="Wegiht"
            />
          ),
          value: DETAIL_VALUES_ACCORDING_TO_DESIGN[design].weight,
        },
        {
          id: "Kizuna.specCompositionDetailWidth",
          description: (
            <FormattedMessage
              id="Kizuna.specCompositionDetailWidth"
              defaultMessage="Width"
            />
          ),
          value: "150 cm | 59 in",
        },
        {
          id: "Kizuna.specCompositionDetailRollSize",
          description: (
            <FormattedMessage
              id="Kizuna.specCompositionDetailRollSize"
              defaultMessage="Roll Size*"
            />
          ),
          value: "30 lm | 32,9 yd",
        },
        {
          id: "Kizuna.specCompositionDetailOpennessFactor",
          description: (
            <FormattedMessage
              id="Kizuna.specCompositionDetailOpennessFactor"
              defaultMessage="Openness factor"
            />
          ),
          value: DETAIL_VALUES_ACCORDING_TO_DESIGN[design].openessFactor,
        },
        {
          id: "Kizuna.specCompositionDetailSurfaceComposition",
          description: (
            <FormattedMessage
              id="Kizuna.specCompositionDetailSurfaceComposition"
              defaultMessage="Surface Composition"
            />
          ),
          value: (
            <FormattedMessage
              id="Kizuna.spectCompositionSurfaceCompositionValue"
              defaultMessage="71% PVC-Coated Polyester + 29% Solution Dyed"
            />
          ),
        },
      ],
      disclaimer: [
        {
          id: "Kizuna.specCompositionDetailRollSize.disclaimer",
          value: (
            <FormattedMessage
              id="Kizuna.specCompositionDetailRollSize.disclaimer"
              defaultMessage="* Typical roll length. Length may vary depending on the availability  of standard roll size."
            />
          ),
        },
      ],
    },
    [FEATURES_AND_BENEFITS]: {
      info: [{ value: <FeaturesAndBenefitsElements /> }],
      disclaimer: [
        {
          id: "Kizuna.featuresAndBenefits.reversible.disclaimer",
          value: (
            <FormattedMessage
              id="Kizuna.featuresAndBenefits.reversible.disclaimer"
              defaultMessage="* Can be flipped to reveal a second color or pattern. Both faces can be used."
            />
          ),
        },
        {
          id: "Kizuna.featuresAndBenefits.washable.disclaimer",
          value: (
            <FormattedMessage
              id="Kizuna.featuresAndBenefits.washable.disclaimer"
              defaultMessage="** Please read Cleaning instructions."
            />
          ),
        },
      ],
    },
    [APPLICATIONS]: { info: [{ value: <ApplicationsElements /> }] },
    [FINISHES_AND_TREATMENTS]: {
      info: [
        {
          id: "Kizuna.specFinishesTreatmentsSprayTest",
          description: (
            <FormattedMessage
              id="Kizuna.specFinishesTreatmentsSprayTest"
              defaultMessage="Spray test"
            />
          ),
          value: "≥ 70",
        },
        {
          id: "Kizuna.specFinishesTreatmentsColourFastness",
          description: (
            <FormattedMessage
              id="Kizuna.specFinishesTreatmentsColourFastness"
              defaultMessage="Colour fastness to domestic or commercial laundering 30ºC | 86 ºF"
            />
          ),
          value:
            DETAIL_VALUES_ACCORDING_TO_DESIGN[design].colorFatnessToDomestic,
        },
        {
          id: "Kizuna.specFinishesTreatmentsColourFastnessChlorinatedWater",
          description: (
            <FormattedMessage
              id="Kizuna.specFinishesTreatmentsColourFastnessChlorinatedWater"
              defaultMessage="Colour fastness to chlorinated water"
            />
          ),
          value: "≥ 3/5",
        },
        {
          id: "Kizuna.specFinishesTreatmentsColourFastnessToWater",
          description: (
            <FormattedMessage
              id="Kizuna.specFinishesTreatmentsColourFastnessToWater"
              defaultMessage="Colour fastness to sea water"
            />
          ),
          value: "≥ 4/5",
        },
        {
          id: "Kizuna.specFinishesTreatmentsColourFastnessToRubbing",
          description: (
            <FormattedMessage
              id="Kizuna.specFinishesTreatmentsColourFastnessToRubbing"
              defaultMessage="Colour fastness to rubbing (wet/dry)"
            />
          ),
          value: "5",
        },
        {
          id: "Kizuna.specFinishesTreatmentsUVResistance",
          description: (
            <FormattedMessage
              id="Kizuna.specFinishesTreatmentsUVResistance"
              defaultMessage="UV Resistance"
            />
          ),
          value: "≥ 7/8",
        },
        {
          id: "Kizuna.specFinishesTreatmentsAbrasion",
          description: (
            <FormattedMessage
              id="Kizuna.specFinishesTreatmentsAbrasion"
              defaultMessage="Abrasion"
            />
          ),
          value: (
            <FormattedMessage
              id="Kizuna.specFinishesTreatmentsAbrasionValue"
              defaultMessage="≥ 100.000 cycles Martindale"
            />
          ),
        },
        {
          id: "Kizuna.specFinishesTreatmentsPilling",
          description: (
            <FormattedMessage
              id="Kizuna.specFinishesTreatmentsPilling"
              defaultMessage="Pilling"
            />
          ),
          value: DETAIL_VALUES_ACCORDING_TO_DESIGN[design].pilling,
        },
        {
          id: "Kizuna.specFinishesTreatmentsChangeInWashingAndDrying",
          description: (
            <FormattedMessage
              id="Kizuna.specFinishesTreatmentsChangeInWashingAndDrying"
              defaultMessage="Determination of dimensional change in washing and drying"
            />
          ),
          value: "± 0,5 %",
        },
        {
          id: "Kizuna.specFinishesTreatmentsThermalLimits",
          description: (
            <FormattedMessage
              id="Kizuna.specFinishesTreatmentsThermalLimits"
              defaultMessage="Thermal Limits"
            />
          ),
          value: "-30ºC / +80ºC -22ºF / +176ºF",
        },
        {
          id: "Kizuna.specFinishesTreatmentsMouldResistance",
          description: (
            <FormattedMessage
              id="Kizuna.specFinishesTreatmentsMouldResistance"
              defaultMessage="Mould Resistance"
            />
          ),
          value: (
            <FormattedMessage
              id="Kizuna.specFinishesTreatmentsMouldResistanceValue"
              defaultMessage="0: No growth"
            />
          ),
        },
        {
          id: "Kizuna.specFinishesTreatmentsMethodB",
          description: (
            <FormattedMessage
              id="Kizuna.specFinishesTreatmentsMethodB"
              defaultMessage="Method B"
            />
          ),
          value: (
            <FormattedMessage
              id="Kizuna.specFinishesTreatmentsMethodBValue"
              defaultMessage="No growth"
            />
          ),
        },
        {
          id: "Kizuna.specFinishesTreatmentsSlingProperties",
          description: (
            <FormattedMessage
              id="Kizuna.specFinishesTreatmentsSlingProperties"
              defaultMessage="Sling properties**"
            />
          ),
          value: "**",
        },
        {
          id: "Kizuna.specFinishesTreatmentsPFAS-Free",
          description: (
            <FormattedMessage
              id="Kizuna.specFinishesTreatmentsPFAS-Free"
              defaultMessage="PFAS-Free"
            />
          ),
          value: "✔",
        },
        {
          id: "Kizuna.specFinishesTreatmentsFluorineFree",
          description: (
            <FormattedMessage
              id="Kizuna.specFinishesTreatmentsFluorineFree"
              defaultMessage="Fluorine-free"
            />
          ),
          value: "✔",
        },
        {
          id: "Kizuna.specFinishesTreatmentsPhthalateFree",
          description: (
            <FormattedMessage
              id="Kizuna.specFinishesTreatmentsPhthalateFree"
              defaultMessage="Phthalate Free"
            />
          ),
          value: "✔",
        },
      ],
      disclaimer: [
        {
          id: "Kizuna.specFinishesTreatmentsSlingProperties.disclaimer",
          value: (
            <FormattedMessage
              id="Kizuna.specFinishesTreatmentsSlingProperties.disclaimer"
              defaultMessage="** The performance of the Kizuna collection in sling applications relies on the proper furniture manufacturing process and its structure design. Please refer to the furniture manufacturer's instructions, warnings, and guidelines. Prior testing is recommended."
            />
          ),
        },
      ],
    },
    [FLAME_RETARDANCY]: {
      info: [
        {
          id: "Kizuna.specFlameRetardancyEN",
          description: (
            <FormattedMessage
              id="Kizuna.specFlameRetardancyEN"
              defaultMessage="EN 1021 Part 1+2"
            />
          ),
          value: "Pass",
        },
        {
          id: "Kizuna.specFlameRetardancyDIN",
          description: (
            <FormattedMessage
              id="Kizuna.specFlameRetardancyDIN"
              defaultMessage="DIN 4102 B2"
            />
          ),
          value: "B2",
        },
        {
          id: "Kizuna.specFlameRetardancyCAL",
          description: (
            <FormattedMessage
              id="Kizuna.specFlameRetardancyCAL"
              defaultMessage="CAL TB 117-2013"
            />
          ),
          value: "Pass",
        },
        {
          id: "Kizuna.specFlameRetardancyIMO",
          description: (
            <FormattedMessage
              id="Kizuna.specFlameRetardancyIMO"
              defaultMessage="IMO 2010 FTP Part 8"
            />
          ),
          value: "Pass",
        },
      ],
      disclaimer: [
        {
          id: "Kizuna.specFlameRetardancy.disclaimer",
          value: (
            <FormattedMessage
              id="Kizuna.specFlameRetardancy.disclaimer"
              defaultMessage="*** This term and any corresponding data refer to the typical performance in the specific tests indicated and should not be construed to imply the behaviour of this or any other material under actual fire conditions."
            />
          ),
        },
      ],
    },
    [ADDITIONAL_TEST]: {
      info: [
        {
          id: "Kizuna.specAdditionalTestTensileStrength",
          description: (
            <FormattedMessage
              id="Kizuna.specAdditionalTestTensileStrength"
              defaultMessage="Tensile strength (warp/weft)"
            />
          ),
          value: DETAIL_VALUES_ACCORDING_TO_DESIGN[design].tensibleStrength,
        },
        {
          id: "Kizuna.specAdditionalTestElongation",
          description: (
            <FormattedMessage
              id="Kizuna.specAdditionalTestElongation"
              defaultMessage="Elongation (warp/weft)"
            />
          ),
          value: DETAIL_VALUES_ACCORDING_TO_DESIGN[design].elongation,
        },
        {
          id: "Kizuna.specAdditionalTestElongationUnder",
          description: (
            <FormattedMessage
              id="Kizuna.specAdditionalTestElongationUnder"
              defaultMessage="Elongation under constant load (warp/weft)"
            />
          ),
          value:
            DETAIL_VALUES_ACCORDING_TO_DESIGN[design].elongationUnderConstant,
        },
        {
          id: "Kizuna.specAdditionalTestTearingResistance",
          description: (
            <FormattedMessage
              id="Kizuna.specAdditionalTestTearingResistance"
              defaultMessage="Tearing resistance (warp/weft)"
            />
          ),
          value: DETAIL_VALUES_ACCORDING_TO_DESIGN[design].tearingResistance,
        },
        {
          id: "Kizuna.specAdditionalTestSeamResistance",
          description: (
            <FormattedMessage
              id="Kizuna.specAdditionalTestSeamResistance"
              defaultMessage="Seam resistance (warp/weft)"
            />
          ),
          value: DETAIL_VALUES_ACCORDING_TO_DESIGN[design].seamResistance,
        },
        {
          id: "Kizuna.specAdditionalTestCoatingAdhesion",
          description: (
            <FormattedMessage
              id="Kizuna.specAdditionalTestCoatingAdhesion"
              defaultMessage="Determination of coating adhesion"
            />
          ),
          value: "2,5 daN/cm",
        },
      ],
      disclaimer: [],
    },
    [WARRANTY]: {
      info: [
        {
          id: "Kizuna.specWarrantyYears",
          description: (
            <FormattedMessage
              id="Kizuna.specWarrantyYears"
              defaultMessage="5 years"
            />
          ),
        },
      ],
      disclaimer: [
        {
          id: "Kizuna.specWarrantyTermsAndConditions",
          value: (
            <FormattedMessage
              id="Kizuna.specWarrantyTermsAndConditions"
              defaultMessage="Terms and conditions apply"
            />
          ),
        },
      ],
    },
  };

  return PRODUCT_SPECS_DETAIL_INFORMATION;
};
