import { useStateMachine } from "little-state-machine";
import { updateAction } from "app/store";

const useSampleCard = ({ design, handleShowInfoBox }) => {
  const { state, actions } = useStateMachine({ updateAction });

  const handleAddSample = (currentSample) => {
    const newSample = { pattern: design, ...currentSample };

    const updateSamplesCount = state?.sampleCount
      ? [...state?.sampleCount, newSample]
      : [newSample];

    actions.updateAction({
      sampleCount: updateSamplesCount,
    });

    handleShowInfoBox();
  };

  return { handleAddSample };
};

export default useSampleCard;
