import Header from "components/Header";
import Hero from "components/Hero";
import KizunaHistory from "components/KizunaHistory";
import Footer from "components/Footer";
import { COLLECTION_VIMEO_VIDEO_URL } from "modules/home/constants";
import { getCurrentLanguage } from "services/instances";

const KizunaCollections = () => {
  const lang = getCurrentLanguage();

  return (
    <div className="kizuna-collection">
      <Header />
      <Hero
        isKizunaHistory
        isAutoPlay={false}
        bannerVideo={COLLECTION_VIMEO_VIDEO_URL[lang]}
      />
      <KizunaHistory />
      <Footer />
    </div>
  );
};

export default KizunaCollections;
