import { useEffect } from "react";
import classNames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import { FormattedMessage } from "react-intl";
import { HiOutlinePlayCircle } from "react-icons/hi2";
import { KIZUNA_DESIGNS } from "components/Designs/constants";
import RequestsSection from "components/RequestsSection";
import { REQUEST_SAMPLES_INFO } from "components/RequestsSection/utils";
import { XL } from "consts/breakpoints";
import useMediaQuery from "hooks/useMediaQuery";
import { getCurrentLanguage } from "services/instances";
import ScrollDown from "../ScrollDown";
import { updateAction } from "app/store";
import { SAMPLES } from "components/Designs/components/ProductsSamples/constants/samples";
import VimeoVideo from "./components/VimeoVideo";
import "./styles/Hero.scss";
import { COLLECTION_YOUTUBE_VIDEO_URL } from "modules/home/constants";
import useRequestModal from "components/Footer/useRequestModal";

export default function Hero({ isKizunaHistory, isAutoPlay, bannerVideo }) {
  const navigate = useNavigate();

  const lang = getCurrentLanguage();
  const location = useLocation();
  const { state, actions } = useStateMachine({ updateAction });
  const isMobile = useMediaQuery(XL);
  const { modalContext, toggable, toggle, handleOrderCatalogue } =
    useRequestModal();

  const handleClickInADesignTitle = (design) => {
    const sampleDetails = SAMPLES.filter(
      (designDetails) => designDetails.designGroup === design
    )[0].colors[0];

    actions.updateAction({
      showUnderlined: {
        checker: design === KIZUNA_DESIGNS[0] ? true : false,
        artisan: design === KIZUNA_DESIGNS[1] ? true : false,
        tweed: design === KIZUNA_DESIGNS[2] ? true : false,
      },
      productDetails: {
        title: design,
        sampleDetails,
      },
    });

    const access = document.getElementById("designs");
    if (access) {
      access.scrollIntoView({ behavior: "smooth" }, true);
    }

    navigate(`/${getCurrentLanguage()}/${design.toLowerCase()}`);
  };

  useEffect(() => {
    window.addEventListener("beforeunload", () => {
      actions.updateAction({
        showUnderlined: {},
        sampleCount: [],
      });
    });
  }, [actions, state]);

  return (
    <>
      <div className="hero">
        {!isMobile && (
          <VimeoVideo
            id="video-container"
            className="videoembed"
            video={bannerVideo}
            isAutoPlay={isAutoPlay}
          />
        )}
        {isMobile && isKizunaHistory && (
          <a
            href={COLLECTION_YOUTUBE_VIDEO_URL[lang]}
            target="_blank"
            rel="noreferrer"
          >
            <HiOutlinePlayCircle className="play-icon" />
            <span className="play-icon-label">
              <FormattedMessage
                id="Kizuna.banner.showVideo"
                defaultMessage="Show video"
              />
            </span>
          </a>
        )}
        {!isKizunaHistory && (
          <>
            <div className="hero-description-container">
              <p
                className={classNames({
                  "description-text": true,
                  "text-size-small": isMobile,
                })}
              >
                <FormattedMessage
                  id="Kizuna.bannerDescription"
                  defaultMessage="Blending their expertise, quality, and design {br}Spradling<sup>®</sup> and Citel<sup>®</sup> introduce the Kizuna {br} Collection: new innovative fabrics that embody the {br} true essence of cooperation and togetherness."
                  values={{
                    sup: (...chunks) => <sup>{chunks}</sup>,
                    br: <br />,
                  }}
                />
              </p>
              <button
                type="button"
                className={classNames({
                  "btn-base btn-outline-light": true,
                  "btn-sm": isMobile,
                })}
                onClick={() => handleOrderCatalogue(REQUEST_SAMPLES_INFO)}
              >
                <FormattedMessage
                  id="Kizuna.buttonOrderKizunaCatalogue"
                  description="order samples"
                  defaultMessage="Order Kizuna Catalogue Here!"
                />
              </button>
            </div>
            <div className="design-navigator">
              {KIZUNA_DESIGNS.map((design) => (
                <h2
                  key={`key_${design}`}
                  className={classNames({
                    "design-link animation-link": true,
                    "underlined-active":
                      KIZUNA_DESIGNS.includes(design) &&
                      (state?.showUnderlined
                        ? state?.showUnderlined[design.toLowerCase()]
                        : false),
                  })}
                  onClick={() => handleClickInADesignTitle(design)}
                  data-testid="kizuna_design"
                >
                  {design}
                </h2>
              ))}
            </div>
          </>
        )}
      </div>

      <RequestsSection
        modalContext={modalContext}
        toggable={toggable}
        toggle={toggle}
      />

      {location.pathname === "/" && <ScrollDown />}
    </>
  );
}
