// Deployed -->
// Desktop
import test_slide1 from "modules/home/imgs/desktop/desktop-1.jpg";
import test_slide2 from "modules/home/imgs/desktop/desktop-2.jpg";
import test_slide3 from "modules/home/imgs/desktop/desktop-3.jpg";
import test_slide7 from "modules/home/imgs/desktop/desktop-7.jpg";
import test_slide10 from "modules/home/imgs/desktop/desktop-10.jpg";
// Mobile
import mobile_test_slide1 from "modules/home/imgs/mobile/Mobile-1.jpg";
import mobile_test_slide2 from "modules/home/imgs/mobile/Mobile-2.jpg";
import mobile_test_slide3 from "modules/home/imgs/mobile/Mobile-3.jpg";
import mobile_test_slide8 from "modules/home/imgs/mobile/Mobile-8.jpg";
import mobile_test_slide11 from "modules/home/imgs/mobile/Mobile-11.jpg";
// <-- Deployed

export const SLIDES_DESKTOP = [
  {
    id: 1,
    src: test_slide1,
  },
  {
    id: 2,
    src: test_slide2,
  },
  {
    id: 3,
    src: test_slide3,
  },
  {
    id: 7,
    src: test_slide7,
  },
  {
    id: 10,
    src: test_slide10,
  },
];

export const SLIDES_MOBILE = [
  {
    id: 1,
    src: mobile_test_slide1,
  },
  {
    id: 2,
    src: mobile_test_slide2,
  },
  {
    id: 3,
    src: mobile_test_slide3,
  },
  {
    id: 8,
    src: mobile_test_slide8,
  },
  {
    id: 11,
    src: mobile_test_slide11,
  },
];

export const MAIN_VIDEO_URL =
  "https://player.vimeo.com/video/867816293?h=f3ad3e0cfd";

export const COLLECTION_VIMEO_VIDEO_URL = {
  de: "https://vimeo.com/876403477/80b208d201",
  en: "https://vimeo.com/868295869/6784ecf848",
  es: "https://vimeo.com/876402847/f80f9aaef3",
  fr: "https://vimeo.com/876403156/f5656a9422",
  it: "https://vimeo.com/876403823/116285bd3c",
  pl: "https://vimeo.com/876404257/fb8779cc57",
};

export const COLLECTION_YOUTUBE_VIDEO_URL = {
  de: "https://youtu.be/Rf63x1KiNtQ",
  en: "https://youtu.be/x3jxbJovGOw",
  es: "https://youtu.be/8x0efqVpMT4",
  fr: "https://youtu.be/uIVKqG90z84",
  it: "https://youtu.be/ZSirUUCkUu0",
  pl: "https://youtu.be/zp7PZl222qo",
};
