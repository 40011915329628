import { useContext, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Button, Col, Row } from "reactstrap";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import useToggle from "hooks/useToggle";
import ErrorMessage from "components/ErrorMessage";
import ModalContext from "components/SamplingRequest/context";
import ModalPrivacyPolicy from "./components/ModalPrivacyPolicy";
import { getCurrentLanguage } from "services/instances";
import createStoreLead from "modules/ComingSoonPage/mutation";
import validationMessages from "utils/forms/messages";
import emailBody from "utils/forms/email/body.js";
import {
  REQUEST_SAMPLES_INFO,
  MODAL_TYPES_DOWNLOAD,
  CONTACT_INFO,
  MODAL_TYPE_REQUEST,
} from "components/RequestsSection/utils";
import { EMAIL_MESSAGES } from "./constants/messages";
import { emailsCountries } from "./constants/emails";
import { COUNTRIES_FOR_EUROPE } from "./constants/countries";
import "../../styles/samplingrequest.scss";
import ColorsSelect from "components/SamplingRequest/components/ColorsSelect";
import { ENDPOINT_ZAP } from "./constants";
import axios from "axios";
import { useStateMachine } from "little-state-machine";
import { updateAction } from "app/store";
import { useNavigate } from "react-router-dom";

const COLOR_SCHEMA = Yup.object().shape({
  label: Yup.string().required(),
  value: Yup.string().required(),
  data: Yup.object().shape({
    catalogue: Yup.string(),
    pattern: Yup.string(),
    color: Yup.string(),
    reference: Yup.string(),
  }),
});

export default function RequestForm() {
  const intl = useIntl();
  const navigate = useNavigate();
  const { state, actions } = useStateMachine({ updateAction });
  const context = useContext(ModalContext);
  const [toggle, toggable] = useToggle();
  const [checked, setChecked] = useState(false);
  const [createLead] = useMutation(createStoreLead);

  const initialValues = {
    colours: "",
    firstName: "",
    lastName: "",
    companyName: "",
    companyType: "",
    country: "",
    address: "",
    postalCode: "",
    city: "",
    email: "",
    phone: "",
    comment: "",
    type: context.type,
    privacyPolicy: false,
  };

  const validationSchema = Yup.object({
    colours:
      context === REQUEST_SAMPLES_INFO
        ? Yup.array()
            .of(COLOR_SCHEMA)
            .required(intl.formatMessage(validationMessages.required))
        : Yup.string(),
    firstName: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    lastName: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    companyName: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    companyType: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    email: Yup.string()
      .email()
      .required(intl.formatMessage(validationMessages.required)),
    phone: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    country: Yup.string().required(
      intl.formatMessage(validationMessages.required)
    ),
    address:
      context === REQUEST_SAMPLES_INFO
        ? Yup.string().required(intl.formatMessage(validationMessages.required))
        : Yup.string(),
    postalCode:
      context === REQUEST_SAMPLES_INFO
        ? Yup.string().required(intl.formatMessage(validationMessages.required))
        : Yup.string(),
    city:
      context === REQUEST_SAMPLES_INFO
        ? Yup.string().required(intl.formatMessage(validationMessages.required))
        : Yup.string(),
    comment:
      context === CONTACT_INFO
        ? Yup.string().required(intl.formatMessage(validationMessages.required))
        : Yup.string(),
    privacyPolicy: Yup.bool().oneOf(
      [true],
      intl.formatMessage(validationMessages.required)
    ),
  });

  function getEmailBody(values) {
    const messages = [
      "intro",
      "summaryTitle",
      "product",
      "customerDetails",
      "shippingInformation",
    ].map((name) => intl.formatMessage(EMAIL_MESSAGES[name]));

    return emailBody(messages, values);
  }

  function getClientEmailInfo(values) {
    return {
      client_to: values.email,
      client_subject: intl.formatMessage(EMAIL_MESSAGES.clientSubject),
    };
  }

  function getAdminEmailInfo(values) {
    let admin_to;

    if (getCurrentLanguage() !== "fr") {
      admin_to =
        emailsCountries[values.country] !== undefined
          ? emailsCountries[values.country]
          : "info.en@spradling.group";
    } else {
      admin_to = "a.barrenechea@spradling.group";
    }

    return {
      admin_to,
      admin_subject: intl.formatMessage(EMAIL_MESSAGES.adminSubject),
    };
  }

  const handleZap = async (values) => {
    const emailBody = MODAL_TYPE_REQUEST.includes(context.type)
      ? getEmailBody(values)
      : null;
    const clientEmailInfo = MODAL_TYPE_REQUEST.includes(context.type)
      ? getClientEmailInfo(values)
      : null;
    const adminEmailInfo = MODAL_TYPE_REQUEST.includes(context.type)
      ? getAdminEmailInfo(values)
      : null;

    const data = JSON.stringify({
      ...values,
      ...clientEmailInfo,
      ...adminEmailInfo,
      emailBody,
      date: new Date().toLocaleString(),
    });

    try {
      await axios.post(ENDPOINT_ZAP, data);
    } catch (e) {
      console.log("handleZap ERROR -> ", e);
    }
  };

  const handlePostSubmit = ({ setSubmitting, resetForm }) => {
    actions.updateAction({
      ...state,
      sampleCount: [],
    });
    setSubmitting(false);
    resetForm();
  };

  const handleSubmit = async (
    { privacyPolicy, colours, comment, ...values },
    { setSubmitting, resetForm }
  ) => {
    const processedColors = !colours.length
      ? []
      : colours.map(({ value }) => value);
    const input = {
      source: "Kizuna",
      colors: processedColors,
      comments: comment,
      ...values,
    };
    const inputZap = {
      ...input,
      colors: processedColors,
      colorsForTemplateEmail: colours,
    };

    try {
      await createLead({ variables: { input } });
      await handleZap(inputZap);
      handlePostSubmit({ setSubmitting, resetForm });
      navigate(`/${getCurrentLanguage()}/thank-you/${context.thanksUrl}`, {
        state: JSON.stringify(context),
      });
    } catch (e) {
      console.log("ERROR crete contact on CONTACTFORM: ", e);
      handlePostSubmit({ setSubmitting, resetForm });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, isSubmitting }) => (
        <Form noValidate className="px-lg-5">
          <Row>
            <Col md={12}>
              <p className="text-center px-5 mb-4">{context.subtitle}</p>
            </Col>
          </Row>
          {context === REQUEST_SAMPLES_INFO && (
            <>
              <Row>
                <Col md={12}>
                  <div className="form-group">
                    <ColorsSelect id="colours" name="colours" />
                    <ErrorMessage name="colours" />
                  </div>
                </Col>
              </Row>
            </>
          )}

          <Row>
            <Col md={6}>
              <div className="form-group">
                <Field
                  placeholder={intl.formatMessage({
                    id: "SamplingRequest.labelName",
                    defaultMessage: "Name",
                  })}
                  id="firstName"
                  name="firstName"
                  type="text"
                  className="form-control rounded-0 mt-4"
                />
                <ErrorMessage name="firstName" />
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <Field
                  placeholder={intl.formatMessage({
                    id: "SamplingRequest.labelSurname",
                    defaultMessage: "Surname",
                  })}
                  id="lastName"
                  name="lastName"
                  type="text"
                  className="form-control rounded-0 mt-4"
                />
                <ErrorMessage name="lastName" />
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <Field
                  placeholder={intl.formatMessage({
                    id: "SamplingRequest.labelCompany",
                    defaultMessage: "Company",
                  })}
                  id="companyName"
                  name="companyName"
                  type="text"
                  className="form-control rounded-0 mt-4"
                />
                <ErrorMessage name="companyName" />
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <Field
                  as="select"
                  id="companyType"
                  name="companyType"
                  className="form-control rounded-0 mt-4 text-secondary"
                >
                  <FormattedMessage
                    id="SamplingRequest.selectCompanyType"
                    defaultMessage="Select a company type"
                  >
                    {(message) => <option selected>{message}</option>}
                  </FormattedMessage>
                  <FormattedMessage
                    id="SamplingRequest.optionManufacturer"
                    defaultMessage="Manufacturer"
                  >
                    {(message) => (
                      <option value="manufacturer">{message}</option>
                    )}
                  </FormattedMessage>
                  <FormattedMessage
                    id="SamplingRequest.optionDistributor"
                    defaultMessage="Distributor"
                  >
                    {(message) => (
                      <option value="distributor">{message}</option>
                    )}
                  </FormattedMessage>
                  <FormattedMessage
                    id="SamplingRequest.optionArchitect"
                    defaultMessage="Architect"
                  >
                    {(message) => <option value="architect">{message}</option>}
                  </FormattedMessage>
                  <FormattedMessage
                    id="SamplingRequest.optionDesigner"
                    defaultMessage="Designer"
                  >
                    {(message) => <option value="designer">{message}</option>}
                  </FormattedMessage>
                  <FormattedMessage
                    id="SamplingRequest.optionOther"
                    defaultMessage="Other"
                  >
                    {(message) => <option value="other">{message}</option>}
                  </FormattedMessage>
                </Field>
                <ErrorMessage name="companyType" />
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <Field
                  as="select"
                  id="country"
                  name="country"
                  className="form-control rounded-0 mt-4 text-secondary"
                >
                  <FormattedMessage
                    id="SamplingRequest.selectCountry"
                    defaultMessage="Select a country"
                  >
                    {(message) => <option value="">{message}</option>}
                  </FormattedMessage>
                  {COUNTRIES_FOR_EUROPE.map((country) => (
                    <option key={country.id} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="country" />
              </div>
            </Col>
            {context === REQUEST_SAMPLES_INFO && (
              <>
                <Col md={6}>
                  <div className="form-group">
                    <Field
                      placeholder={intl.formatMessage({
                        id: "SamplingRequest.labelAddress",
                        defaultMessage: "Address",
                      })}
                      id="address"
                      name="address"
                      type="text"
                      className="form-control rounded-0 mt-4"
                    />
                    <ErrorMessage name="address" />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group">
                    <Field
                      placeholder={intl.formatMessage({
                        id: "SamplingRequest.labelPostal",
                        defaultMessage: "Postal Code",
                      })}
                      id="postalCode"
                      name="postalCode"
                      type="text"
                      className="form-control rounded-0 mt-4"
                    />
                    <ErrorMessage name="postalCode" />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group">
                    <Field
                      id="city"
                      name="city"
                      className="form-control rounded-0 mt-4"
                      placeholder={intl.formatMessage({
                        id: "SamplingRequest.labelCity",
                        defaultMessage: "City",
                      })}
                    />
                    <ErrorMessage name="city" />
                  </div>
                </Col>
              </>
            )}
            <Col md={6}>
              <div className="form-group">
                <Field
                  placeholder={intl.formatMessage({
                    id: "SamplingRequest.labelEmail",
                    defaultMessage: "Email",
                  })}
                  id="email"
                  name="email"
                  type="text"
                  className="form-control rounded-0 mt-4"
                />
                <ErrorMessage name="email" />
              </div>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <Field
                  placeholder={intl.formatMessage({
                    id: "SamplingRequest.labelPhone",
                    defaultMessage: "Phone",
                  })}
                  id="phone"
                  name="phone"
                  type="text"
                  className="form-control rounded-0 mt-4"
                />
                <ErrorMessage name="phone" />
              </div>
            </Col>
            {context.type === CONTACT_INFO.type && (
              <Col md={12}>
                <div className="form-group">
                  <Field
                    placeholder={intl.formatMessage({
                      id: "SamplingRequest.labelComment",
                      defaultMessage: "Comment",
                    })}
                    id="comment"
                    name="comment"
                    as="textarea"
                    className="form-control rounded-0 mt-4"
                  />
                  <ErrorMessage name="comment" />
                </div>
              </Col>
            )}
          </Row>

          <div className="form-group justify-content-between form-check d-lg-flex text-start mt-4 mb-4 footer-form-container">
            <div>
              <Field
                type="checkbox"
                name="privacyPolicy"
                id="privacyPolicy"
                className="form-check-input rounded-0"
                checked={checked}
                onClick={toggable}
              />
              <label
                htmlFor="privacyPolicy"
                className="form-check-label fw-light text-secondary"
              >
                <FormattedMessage
                  id="SamplingRequest.privacyPolicy"
                  defaultMessage="I've read and agree the <strong>Privacy Policy</strong>"
                  values={{
                    strong: (...chunks) => <strong>{chunks}</strong>,
                  }}
                />
              </label>
              <ErrorMessage name="privacyPolicy" />

              <ModalPrivacyPolicy
                isOpen={toggle}
                toggle={toggable}
                acceptPrivacyPolicy={setChecked}
              />
            </div>
            <div className="col-lg-6 text-end d-flex align-items-end justify-content-end py-4 py-lg-0">
              <Button
                color="secondary"
                type="submit"
                disabled={isSubmitting}
                className="btn text-white px-4 button-form py-1"
              >
                {!isSubmitting ? (
                  <p className="m-0 p-0 text-white">
                    {context === REQUEST_SAMPLES_INFO && (
                      <FormattedMessage
                        id="SamplingRequest.btnLabelRequest"
                        defaultMessage="ORDER NOW"
                      />
                    )}
                    {MODAL_TYPES_DOWNLOAD.includes(context.type) && (
                      <FormattedMessage
                        id="SamplingRequest.btnLabelRequestDownloadCatalogue"
                        defaultMessage="DOWNLOAD"
                      />
                    )}
                    {context.type === CONTACT_INFO.type && (
                      <FormattedMessage
                        id="SamplingRequest.btnLabelRequestContactUs"
                        defaultMessage="SEND"
                      />
                    )}
                  </p>
                ) : (
                  <p className="m-0 p-0 text-white">
                    <FormattedMessage
                      id="SamplingRequest.btnLabelLoading"
                      defaultMessage="Sending..."
                    />
                  </p>
                )}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
