const usePatternData = ({ state, design, handleSamplesData }) => {
  const SAMPLES = handleSamplesData(design);
  const { title, sampleDetails } = state?.productDetails || {};
  const currentSample = {
    pattern: title || design,
    sampleDetails: sampleDetails || SAMPLES[0],
  };
  const {
    pattern,
    sampleDetails: { color, reference, imgDetail },
  } = currentSample;

  return { pattern, color, reference, imgDetail, SAMPLES };
};

export default usePatternData;
