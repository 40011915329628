import PropTypes from "prop-types";
import "./styles/SamplesCount.scss";
import { FormattedMessage } from "react-intl";
import { useStateMachine } from "little-state-machine";
import LogoSamples from "../../img/logo-samples.png";

const SamplesCount = ({ showBox, toggle }) => {
  const { state } = useStateMachine();

  return (
    <div className="d-flex justify-content-end sample-count-container">
      {showBox && (
        <div id="comment-box" className="mt-4 chat-bubble mb-0">
          <p>
            <FormattedMessage
              id="Kizuna.youHaveAddedSample"
              defaultMessage="You have added a sample"
            />
          </p>
        </div>
      )}
      <div onClick={() => toggle()}>
        <div className="d-flex align-items-center p-2 cursor-pointer count-samples">
          <h1 className="text-white me-1 mb-0">
            {state?.sampleCount?.length ?? 0}
          </h1>
          <img
            src={LogoSamples}
            width={32}
            height={32}
            alt="logo samples"
            className="logo-color"
          />
        </div>
        <p className="text-center fw-light">
          <FormattedMessage
            id="Kizuna.specSamples"
            defaultMessage="My Samples"
          />
        </p>
      </div>
    </div>
  );
};

SamplesCount.propTypes = {
  showBox: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default SamplesCount;
