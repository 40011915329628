export const COUNTRIES_FOR_EUROPE = [
  {
    id: "Q291bnRyeU5vZGU6MTU=",
    name: "Aland Islands",
    code2: "AX",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6Ng==",
    name: "Albania",
    code2: "AL",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6MQ==",
    name: "Andorra",
    code2: "AD",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6MTI=",
    name: "Austria",
    code2: "AT",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6MzY=",
    name: "Belarus",
    code2: "BY",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6MjA=",
    name: "Belgium",
    code2: "BE",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6MTc=",
    name: "Bosnia and Herzegovina",
    code2: "BA",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6MjI=",
    name: "Bulgaria",
    code2: "BG",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6OTg=",
    name: "Croatia",
    code2: "HR",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6NTU=",
    name: "Cyprus",
    code2: "CY",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6NTY=",
    name: "Czechia",
    code2: "CZ",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6NTk=",
    name: "Denmark",
    code2: "DK",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6NjQ=",
    name: "Estonia",
    code2: "EE",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6NzQ=",
    name: "Faroe Islands",
    code2: "FO",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6NzA=",
    name: "Finland",
    code2: "FI",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6NzU=",
    name: "France",
    code2: "FR",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6NTc=",
    name: "Germany",
    code2: "DE",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6ODM=",
    name: "Gibraltar",
    code2: "GI",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6ODk=",
    name: "Greece",
    code2: "GR",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6ODE=",
    name: "Guernsey",
    code2: "GG",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6MTAw",
    name: "Hungary",
    code2: "HU",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6MTA5",
    name: "Iceland",
    code2: "IS",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6MTAy",
    name: "Ireland",
    code2: "IE",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6MTA0",
    name: "Isle of Man",
    code2: "IM",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6MTEw",
    name: "Italy",
    code2: "IT",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6MTEx",
    name: "Jersey",
    code2: "JE",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6MTIz",
    name: "Kosovo",
    code2: "XK",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6MTM2",
    name: "Latvia",
    code2: "LV",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6MTMw",
    name: "Liechtenstein",
    code2: "LI",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6MTM0",
    name: "Lithuania",
    code2: "LT",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6MTM1",
    name: "Luxembourg",
    code2: "LU",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6MTU0",
    name: "Malta",
    code2: "MT",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6MTQw",
    name: "Moldova",
    code2: "MD",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6MTM5",
    name: "Monaco",
    code2: "MC",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6MTQx",
    name: "Montenegro",
    code2: "ME",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6MTY3",
    name: "Netherlands",
    code2: "NL",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6MTQ1",
    name: "North Macedonia",
    code2: "MK",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6MTY4",
    name: "Norway",
    code2: "NO",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6MTgw",
    name: "Poland",
    code2: "PL",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6MTg1",
    name: "Portugal",
    code2: "PT",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6MTkw",
    name: "Romania",
    code2: "RO",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6MTky",
    name: "Russia",
    code2: "RU",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6MjA2",
    name: "San Marino",
    code2: "SM",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6MTkx",
    name: "Serbia",
    code2: "RS",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6MjUx",
    name: "Serbia and Montenegro",
    code2: "CS",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6MjA0",
    name: "Slovakia",
    code2: "SK",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6MjAy",
    name: "Slovenia",
    code2: "SI",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6Njg=",
    name: "Spain",
    code2: "ES",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6MjAz",
    name: "Svalbard and Jan Mayen",
    code2: "SJ",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6MTk5",
    name: "Sweden",
    code2: "SE",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6NDM=",
    name: "Switzerland",
    code2: "CH",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6MjMx",
    name: "Ukraine",
    code2: "UA",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6Nzc=",
    name: "United Kingdom",
    code2: "GB",
    continent: "Europe",
  },
  {
    id: "Q291bnRyeU5vZGU6MjM3",
    name: "Vatican",
    code2: "VA",
    continent: "Europe",
  },
];
