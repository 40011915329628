import { FEATURES_AND_BENEFITS_ELEMENTS } from "./constants";
import "./styles/features-and-benefits-elements.scss";

const FeaturesAndBenefitsElements = () => (
  <div className="container g-0">
    <div className="row mt-3">
      <div className="d-flex">
        {FEATURES_AND_BENEFITS_ELEMENTS.map(({ img, description }) => (
          <div className="text-center">
            <img className="feature-img" src={img} alt={description} />
            <p className="description-feature">{description}</p>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default FeaturesAndBenefitsElements;
