import { FormattedMessage } from "react-intl";
import marina from "./img/marina.png";
import outdoor from "./img/outdoor.png";

export const APPLICATIONS_ELEMENTS = [
  {
    img: marina,
    description: (
      <FormattedMessage
        id="Kizuna.specApplicationsMarine"
        defaultMessage="Marine"
      />
    ),
  },

  {
    img: outdoor,
    description: (
      <FormattedMessage
        id="Kizuna.specApplicationsOutdoor"
        defaultMessage="Outdoor"
      />
    ),
  },
];
