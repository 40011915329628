import RequestsSection from "components/RequestsSection";
import {
  CONTACT_INFO,
  DIGITAL_CATALOGUE_INFO,
  REQUEST_SAMPLES_INFO,
} from "components/RequestsSection/utils";
import { FormattedMessage } from "react-intl";
import spradlingLogo from "./img/spradling_logo_gray.svg";
import useRequestModal from "./useRequestModal";
import "./style/footer.scss";

export default function Footer() {
  const {
    modalContext,
    toggable,
    toggle,
    handleClickInRequest,
    handleOrderCatalogue,
  } = useRequestModal();

  return (
    <footer className="footer">
      <div className="container-form font-gill-sans my-1 my-md-3 py-md-2">
        <div className="d-md-flex align-items-center footer-logo">
          <div className="desktop d-md-flex">
            <p
              className="text-secondary mb-0 cursor-pointer"
              onClick={() => handleOrderCatalogue(REQUEST_SAMPLES_INFO)}
            >
              <FormattedMessage
                id="Kizuna.orderSamples.footer"
                description="Order samples"
                defaultMessage="REQUEST CATALOGUE"
              />
              {" | "}
            </p>
            <p
              className="text-secondary ms-1 mb-0 cursor-pointer"
              onClick={() => handleClickInRequest(DIGITAL_CATALOGUE_INFO)}
            >
              <FormattedMessage
                id="Kizuna.digitalCatalogue"
                description="Digital Catalogue"
                defaultMessage="DOWNLOAD DIGITAL CATALOGUE"
              />
              {" | "}
            </p>
            <p
              className="text-secondary ms-1 cursor-pointer mb-0"
              onClick={() => handleClickInRequest(CONTACT_INFO)}
            >
              <FormattedMessage
                id="Kizuna.contactUs"
                description="Contact Us"
                defaultMessage="CONTACT US"
              />
            </p>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-lg-0 ">
            <span className="small me-3">
              <FormattedMessage
                id="Kizuna.allRightsReserved"
                description="All rights reserved"
                defaultMessage="All rights reserved."
              />
            </span>
            <img
              className="footer-logo-img w-50"
              src={spradlingLogo}
              alt="..."
            />
          </div>
        </div>
      </div>

      <RequestsSection
        modalContext={modalContext}
        toggable={toggable}
        toggle={toggle}
      />
    </footer>
  );
}
