/* CHECKER */
// SIDE A
import ACQUA from "../img/checker/side-a/ACQUA_CHK-B008.jpg";
import ATLANTIC from "../img/checker/side-a/ATLANTIC_CHK-N010.jpg";
import BOBCAT from "../img/checker/side-a/BOBCAT_CHK-B003.jpg";
import CARDAMOM from "../img/checker/side-a/CARDAMOM_CHK-B004.jpg";
import CEDRUS from "../img/checker/side-a/CEDRUS_CHK-B007.jpg";
import CREAMY from "../img/checker/side-a/CREAMY_CHK-B014.jpg";
import CRYSTAL from "../img/checker/side-a/CRYSTAL_CHK-G010.jpg";
import EMERALD from "../img/checker/side-a/EMERALD_CHK-N009.jpg";
import KHAKI from "../img/checker/side-a/KHAKI_CHK-G003.jpg";
import KSAMIL from "../img/checker/side-a/KSAMIL_CHK-G009.jpg";
import OVERCAST from "../img/checker/side-a/OVERCAST_CHK-B005.jpg";
import SALVIA from "../img/checker/side-a/SALVIA_CHK-N012.jpg";
import SOOT from "../img/checker/side-a/SOOT_CHK-N002.jpg";
import STORMY from "../img/checker/side-a/STORMY_CHK-G001.jpg";
import WINTER from "../img/checker/side-a/WINTER_CHK-B013.jpg";
// SIDE B
import ACQUA_B from "../img/checker/side-b/ACQUA.png";
import ATLANTIC_B from "../img/checker/side-b/ATLANTIC.png";
import BOBCAT_B from "../img/checker/side-b/BOBCAT.png";
import CARDAMOM_B from "../img/checker/side-b/CARDAMOM.png";
import CEDRUS_B from "../img/checker/side-b/CEDRUS.png";
import CREAMY_B from "../img/checker/side-b/CREAMY.png";
import CRYSTAL_B from "../img/checker/side-b/CRYSTAL.png";
import EMERALD_B from "../img/checker/side-b/EMERALD.png";
import KHAKI_B from "../img/checker/side-b/KHAKI.png";
import KSAMIL_B from "../img/checker/side-b/KSAMIL.png";
import OVERCAST_B from "../img/checker/side-b/OVERCAST.png";
import SALVIA_B from "../img/checker/side-b/SALVIA.png";
import SOOT_B from "../img/checker/side-b/SOOT.png";
import STORMY_B from "../img/checker/side-b/STORMY.png";
import WINTER_B from "../img/checker/side-b/WINTER.png";

/* ARTISAN */
// SIDE A
import BARK from "../img/artisan/side-a/BARK_ART-N014.jpg";
import CELESTE from "../img/artisan/side-a/CELESTE_ART-B01.jpg";
import LEADEN from "../img/artisan/side-a/LEADEN_ART-B015.jpg";
import MINT from "../img/artisan/side-a/MINT_ART-B007.jpg";
import SEAFOAM from "../img/artisan/side-a/SEAFOAM_ART-B008.jpg";
import SEAROCK from "../img/artisan/side-a/SEAROCK_ART-G010.jpg";
import TWILIGHT from "../img/artisan/side-a/TWILIGHT_ART-G002.jpg";
import WHEAT from "../img/artisan/side-a/WHEAT_ART-G014.jpg";
// SIDE B
import BARK_B from "../img/artisan/side-b/BARK.png";
import CELESTE_B from "../img/artisan/side-b/CELESTE.png";
import LEADEN_B from "../img/artisan/side-b/LEADEN.png";
import MINT_B from "../img/artisan/side-b/MINT.png";
import SEAFOAM_B from "../img/artisan/side-b/SEAFOAM.png";
import SEAROCK_B from "../img/artisan/side-b/SEAROCK.png";
import TWILIGHT_B from "../img/artisan/side-b/TWILIGHT.png";
import WHEAT_B from "../img/artisan/side-b/WHEAT.png";

/* TWEED IMAGES */
// SIDE A
import ASHY from "../img/tweed/side-a/ASHY_TWD-G013.jpg";
import BUFF from "../img/tweed/side-a/BUFF_TWD-G006.jpg";
import CAPRI from "../img/tweed/side-a/CAPRI_TWD-N010.jpg";
import CEDAR from "../img/tweed/side-a/CEDAR_TWD-G003.jpg";
import DARKEST from "../img/tweed/side-a/DARKEST_TWD-G002.jpg";
import FOGGY from "../img/tweed/side-a/FOGGY_TWD-B016.jpg";
import JANUBIO from "../img/tweed/side-a/JANUBIO_TWD-N008.jpg";
import MANGROVE from "../img/tweed/side-a/MANGROVE_TWD-N004.jpg";
import OAT from "../img/tweed/side-a/OAT_TWD-B014.jpg";
import OTTER from "../img/tweed/side-a/OTTER_TWD-B003.jpg";
import SNOWY from "../img/tweed/side-a/SNOWY_TWD-B013.jpg";
import ZEBRA from "../img/tweed/side-a/ZEBRA_TWD-B001.jpg";
// SIDE B
import ASHY_B from "../img/tweed/side-b/ASHY.png";
import BUFF_B from "../img/tweed/side-b/BUFF.png";
import CAPRI_B from "../img/tweed/side-b/CAPRI.png";
import CEDAR_B from "../img/tweed/side-b/CEDAR.png";
import DARKEST_B from "../img/tweed/side-b/DARKEST.png";
import FOGGY_B from "../img/tweed/side-b/FOGGY.png";
import JANUBIO_B from "../img/tweed/side-b/JANUBIO.png";
import MANGROVE_B from "../img/tweed/side-b/MANGROVE.png";
import OAT_B from "../img/tweed/side-b/OAT.png";
import OTTER_B from "../img/tweed/side-b/OTTER.png";
import SNOWY_B from "../img/tweed/side-b/SNOWY.png";
import ZEBRA_B from "../img/tweed/side-b/ZEBRA.png";

export const CHECKER_IMAGES = {
  ACQUA,
  ATLANTIC,
  BOBCAT,
  CARDAMOM,
  CEDRUS,
  CREAMY,
  CRYSTAL,
  EMERALD,
  KHAKI,
  KSAMIL,
  OVERCAST,
  SALVIA,
  SOOT,
  STORMY,
  WINTER,
  ACQUA_B,
  ATLANTIC_B,
  BOBCAT_B,
  CARDAMOM_B,
  CEDRUS_B,
  CREAMY_B,
  CRYSTAL_B,
  EMERALD_B,
  KHAKI_B,
  KSAMIL_B,
  OVERCAST_B,
  SALVIA_B,
  SOOT_B,
  STORMY_B,
  WINTER_B,
};

export const ARTISAN_IMAGES = {
  BARK,
  CELESTE,
  LEADEN,
  MINT,
  SEAFOAM,
  SEAROCK,
  TWILIGHT,
  WHEAT,
  BARK_B,
  CELESTE_B,
  LEADEN_B,
  MINT_B,
  SEAFOAM_B,
  SEAROCK_B,
  TWILIGHT_B,
  WHEAT_B,
};

export const TWEED_IMAGES = {
  ASHY,
  BUFF,
  CAPRI,
  CEDAR,
  DARKEST,
  FOGGY,
  JANUBIO,
  MANGROVE,
  OAT,
  OTTER,
  SNOWY,
  ZEBRA,
  ASHY_B,
  BUFF_B,
  CAPRI_B,
  CEDAR_B,
  DARKEST_B,
  FOGGY_B,
  JANUBIO_B,
  MANGROVE_B,
  OAT_B,
  OTTER_B,
  SNOWY_B,
  ZEBRA_B,
};
