import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Slider from "components/Carousel";
import ProductsSamplesDetails from "components/Designs/components/ProductsSamplesDetails";
import Footer from "components/Footer";
import Header from "components/Header";
import Hero from "components/Hero";
import ProductDetailsInMobile from "components/ProductDetailsInMobile";
import { LG } from "consts/breakpoints";
import useMediaQuery from "hooks/useMediaQuery";
import {
  SLIDES_DESKTOP,
  SLIDES_MOBILE,
  MAIN_VIDEO_URL,
} from "modules/home/constants";

const ProductDetailsPage = () => {
  const { design } = useParams();
  const isMobile = useMediaQuery(LG);
  const slides = !isMobile ? SLIDES_DESKTOP : SLIDES_MOBILE;

  useEffect(() => {
    const access = document.getElementById("info-section");
    if (access) {
      const offset = 50;
      // Calcula la posición de desplazamiento restando el offset
      const offsetPosition =
        access.getBoundingClientRect().top + window.scrollY - offset;

      // Realiza el desplazamiento suave al elemento con el offset
      window.scrollTo({ top: offsetPosition, behavior: "smooth" });
    }
  }, [design]);

  return (
    <div className="ProductDetails">
      <Header />
      <Hero className="mb-0" bannerVideo={MAIN_VIDEO_URL} />
      {isMobile && <ProductDetailsInMobile design={design} />}
      {!isMobile && (
        <div id="info-section" className="container mt-2 mt-lg-5">
          <div id="container-samples-detail" className="row">
            <ProductsSamplesDetails design={design} />
          </div>
        </div>
      )}
      <Slider slides={slides} />
      <Footer />
    </div>
  );
};

export default ProductDetailsPage;
