import { FormattedMessage } from "react-intl";
import { Button, Container, Modal, ModalBody } from "reactstrap";

const ModalPrivacyPolicy = ({ isOpen, toggle, acceptPrivacyPolicy }) => {
  const handleClickInAcceptPrivacyPolicy = () => {
    acceptPrivacyPolicy(true);
    toggle();
  };

  return (
    <Modal
      size="xl"
      className="modal-lg sampling-request-modal"
      scrollable
      isOpen={isOpen}
      toggle={toggle}
    >
      <ModalBody className="p-5">
        <Container>
          <h1>
            <FormattedMessage
              id="Kizuna.privacyPolicyTitle"
              defaultMessage="Privacy Policy"
            />
          </h1>
          <h2 className="mb-5">
            <FormattedMessage
              id="Kizuna.privacyPolicySubTitle"
              defaultMessage="Contact Form Submission:"
            />
          </h2>
          <p className="mb-5">
            <FormattedMessage
              id="Kizuna.privacyPolicy"
              defaultMessage="As per the General Data Protection Regulation, you are hereby granting consent for the personal data you have provided to be saved in our files and controlled by SPRADLING<sup>®</sup> EUROPE. {br}{br} The data shall be handled to manage your request and keep you up-to-date on our portfolio of products and the latest news on the company. This data shall not be transferred to third parties and shall be saved as long as it is necessary or allowed for the purposes for which it was collected. {br}{br}In any case, you may revoke your consent or exercise your right to access, rectify or erase, limit or oppose to handling, as well as your right to data portability. These requests must be made to the email address {a}dataprotection@spradling.eu{a} or the post address of any of the company's three headquarters:"
              values={{
                br: <br />,
                a: (...chunks) => (
                  <a href="mailto:dataprotection@spradling.eu">{chunks}</a>
                ),
                span: (...chunks) => <span>"{chunks}"</span>,
                sup: (...chunks) => <sup>{chunks}</sup>,
              }}
            />
          </p>
          <p>
            <FormattedMessage
              id="Kizuna.privacyPolicySpradlingContacts"
              defaultMessage="<strong>SPRADLING<sup>®</sup> SPAIN</strong>{br} Carrer Garbí 3 {br} Polígono industrial de Llevant | Sector C {br} 08213 Polinyà {br} Barcelona, Spain {br}{br} <strong>SPRADLING<sup>®</sup> GERMANY</strong> {br} Stadtkoppel 34 {br} D-21337 {br} Lüneburg, Germany  {br}{br} <strong>SPRADLING<sup>®</sup> UK</strong> {br} Spradling House, 14 Electric Avenue,{br}Witham St Hughs, Lincolnshire. LN6 9BJ{br}United Kingdom {br}{br}"
              values={{
                br: <br />,
                strong: (...chunks) => <strong>{chunks}</strong>,
                sup: (...chunks) => <sup>{chunks}</sup>,
              }}
            />
          </p>
          <div className="text-center">
            <Button
              className="text-white"
              onClick={handleClickInAcceptPrivacyPolicy}
            >
              <FormattedMessage
                id="Kizuna.acceptPrivacyPolicyTerms"
                defaultMessage="Accept terms"
              />
            </Button>
          </div>
        </Container>
      </ModalBody>
    </Modal>
  );
};

export default ModalPrivacyPolicy;
