import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { getCurrentLanguage } from "services/instances";
import LogoKizunaWhite from "../../modules/ComingSoonPage/img/kizuna-logo-blanco.png";
import LogoKizunaGray from "../../modules/ComingSoonPage/img/kizuna-logo-gris.png";
import "./styles/toolbar.scss";
import useMediaQuery from "hooks/useMediaQuery";
import { LG } from "consts/breakpoints";
import {
  ACTIVE_STICKY_FOR_DESKTOP,
  ACTIVE_STICKY_FOR_MOBILE,
} from "./constants";
import { useStateMachine } from "little-state-machine";
import { updateAction } from "app/store";

export default function Header() {
  const { actions } = useStateMachine({ updateAction });
  const toolbar = useRef(null);
  const [, setIsmobile] = useState(null);
  const [isSticky, setIsSticky] = useState(false);

  const isResolutionOfMobile = useMediaQuery(LG);

  useEffect(() => {
    const x = window.matchMedia("(min-width: 755px)");
    responsive(x);
    handleStyles(x, isResolutionOfMobile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResolutionOfMobile]);

  function responsive(x) {
    if (x.matches) {
      // If media query matches
      setIsmobile(false);
    } else {
      setIsmobile(true);
    }
  }

  const handleSticky = (activeSticky) => {
    window.onscroll = function (e) {
      if (window.scrollY > activeSticky) {
        setIsSticky(true);
      } else if (window.scrollY < activeSticky) {
        setIsSticky(false);
      }
    };
  };

  function handleStyles(x, isMobile) {
    x.addEventListener("change", responsive);
    if (toolbar && toolbar.current) {
      !isMobile
        ? handleSticky(ACTIVE_STICKY_FOR_DESKTOP)
        : handleSticky(ACTIVE_STICKY_FOR_MOBILE);
    }
  }

  const handleClickInSpradlingLogo = () => {
    actions.updateAction({
      showUnderlined: {},
    });
  };

  return (
    <header
      className={classNames({
        "Toolbar py-md-3": true,
        "upholstery-glassmorphism": isSticky,
      })}
      ref={toolbar}
    >
      <div className="px-2 px-md-5">
        <div className="d-flex align-items-center justify-content-between">
          <Link
            className="navbar-brand"
            to={`/${getCurrentLanguage()}`}
            onClick={handleClickInSpradlingLogo}
          >
            <img
              className="logo"
              src={isSticky ? LogoKizunaGray : LogoKizunaWhite}
              alt="logo kizuna"
            />
          </Link>
          <h3
            className={classNames({
              "d-flex col-8 col-md-8 col-lg-9 col-xl-3 text-md-end d-flex m-0 align-items-center justify-content-end cursor-pointer fw-bold": true,
              "text-black": isSticky,
              "text-white": !isSticky,
            })}
          >
            <div className="d-flex align-items-center">
              <a
                className={classNames({
                  "font-story": true,
                  "text-white": !isSticky,
                  "text-secondary": isSticky,
                })}
                href={`/${getCurrentLanguage()}/about`}
                target="_blank"
                rel="noreferrer"
              >
                <FormattedMessage
                  id="Kizuna.kizunaCollection"
                  description="Kizuna collection"
                  defaultMessage="Kizuna Collection"
                />
              </a>
            </div>
          </h3>
        </div>
      </div>
    </header>
  );
}
