// TODO: remove COLORS and unused functions and files
const COLORS = [];

export const handleProcessedColors = () => {
  return COLORS.map(({ node }) => node);
};

export const handleAddedSamplesByTheUser = (samples) =>
  samples?.map(({ pattern, color, reference, catalogue }) => {
    if (catalogue)
      return { label: catalogue, value: catalogue, data: { catalogue } };

    const sampleInfo = `${pattern} ${color}|${reference}`.toUpperCase();
    return {
      label: sampleInfo,
      value: sampleInfo,
      data: { pattern, color, reference }, // WARNING: used for build email template
    };
  });
