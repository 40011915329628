import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Button } from "reactstrap";
import { useStateMachine } from "little-state-machine";
import { updateAction } from "app/store";
import "./styles/SampleCard.scss";
import useSampleCard from "./useSampleCard";

const SampleCard = ({ design, sample, handleShowInfoBox }) => {
  const { actions } = useStateMachine({ updateAction });
  const { handleAddSample } = useSampleCard({ design, handleShowInfoBox });

  const handleClickInAnySample = (sample) => {
    const sampleDetails = {
      color: sample.color,
      reference: sample.reference,
      img: sample.img,
      imgDetail: sample.imgDetail,
    };

    actions.updateAction({
      productDetails: {
        title: design,
        sampleDetails,
      },
    });
  };

  return (
    <div
      className="grow-on-hover my-1 mx-1 mx-md-2"
      onClick={() => handleClickInAnySample(sample)}
    >
      <div className="img-container">
        <img width="400%" height="400%" src={sample.img} alt="" />
      </div>
      <span className="label fw-normal">{sample.color}</span>
      <span className="label label-ref fw-light mb-0 mb-md-1">
        {sample.reference}
      </span>
      <Button
        color="secondary"
        className="add-sample-button"
        onClick={() => handleAddSample(sample)}
      >
        <span className="label">
          <FormattedMessage
            id="Kizuna.buttonAddA4Samples"
            description="Add a4 samples"
            defaultMessage="Add"
          />
        </span>
      </Button>
    </div>
  );
};

SampleCard.propTypes = {
  design: PropTypes.string.isRequired,
  handleShowInfoBox: PropTypes.func.isRequired,
  sample: PropTypes.object.isRequired,
};

export default SampleCard;
