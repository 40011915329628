import { DIGITAL_CATALOGUE_INFO } from "components/RequestsSection/utils";
import { handleURLPdfAccordingToFormType } from "components/SamplingRequest/components/RequestForm/constants";

export const handleDownloadFile = async ({ lang, formType }) => {
  try {
    const { pdfURL, fileName } = handleURLPdfAccordingToFormType({
      lang,
      formType,
    });
    const response = await fetch(pdfURL, {
      method: "GET",
      headers: {},
    });

    if (!response.ok) {
      console.log("HTTP error, status =", response.status);
      return;
    }

    const blob = await response.blob();
    const objectUrl = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = objectUrl;
    link.download =
      formType !== DIGITAL_CATALOGUE_INFO.type
        ? `${lang}_${fileName}.pdf`
        : `${fileName}.pdf`;
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(objectUrl);
  } catch (error) {
    console.error("Error during fetch/download operation:", error);
  }
};
