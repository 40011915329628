import "./styles/product-information-detail.scss";

const ProductInformationDetail = ({ details }) => (
  <div>
    <table className="col-12">
      {details.info.map(({ id, description, value }) => (
        <tr key={`key_${id}`} className="info">
          {description && (
            <td className="col-7 text-capitalize">{description}</td>
          )}
          <td className="col-5">{value}</td>
        </tr>
      ))}
    </table>
    {details?.disclaimer && details.disclaimer.length > 0 && (
      <div className="info">
        {details.disclaimer.map(({ id, value }) => (
          <p key={`key_${id}`} className="disclaimer">
            {value}
          </p>
        ))}
      </div>
    )}
  </div>
);

export default ProductInformationDetail;
