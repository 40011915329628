import { NAV_ITEMS } from "components/Header/constants";
import React, { useState } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from "reactstrap";
import "./styles/carousel.scss";
import useMediaQuery from "hooks/useMediaQuery";
import { SM } from "consts/breakpoints";

const Slider = ({ slides }) => {
  const isMobile = useMediaQuery(SM);
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === slides.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? slides.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const SLIDES = slides.map(({ id, src, desktopStyle, mobileStyle }) => {
    return (
      <CarouselItem
        className="custom-tag"
        tag="div"
        key={id}
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
      >
        <img
          className="slide-img"
          style={!isMobile ? desktopStyle : mobileStyle}
          src={src}
          alt="slide"
        />
      </CarouselItem>
    );
  });

  return (
    <div id={NAV_ITEMS[2]}>
      <Carousel
        className="custom-tag"
        activeIndex={activeIndex}
        next={next}
        previous={previous}
      >
        <CarouselIndicators
          items={slides}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        {SLIDES}
        <CarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={previous}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={next}
        />
      </Carousel>
    </div>
  );
};

export default Slider;
