import PropTypes from "prop-types";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import TitleUnderlined from "components/TitleUnderlined";
import { H1_TAG } from "components/TitleUnderlined/constants";
import "./styles/thankYou.scss";
import { MODAL_TYPES_DOWNLOAD } from "components/RequestsSection/utils";
import { SUCCESS_FORM_MESSAGES } from "components/SamplingRequest/components/RequestForm/constants/messages";
import useMediaQuery from "hooks/useMediaQuery";
import { MD } from "consts/breakpoints";
import classNames from "classnames";
import LogoKizunaGray from "modules/ComingSoonPage/img/kizuna-logo-gris.png";
import { getCurrentLanguage } from "services/instances";

const ThankYou = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const intl = useIntl();
  const isMobile = useMediaQuery(MD);
  const {
    title: { props: titleMessage },
    type: formType,
  } = JSON.parse(location.state);

  return (
    <div className="container py-4">
      <Link to={`/${getCurrentLanguage()}`}>
        <img
          width={isMobile ? 80 : 152}
          src={LogoKizunaGray}
          alt="logo kizuna"
        />
      </Link>
      <div className="row my-5 py-5">
        <div className="col">
          <TitleUnderlined
            className="my-5"
            hTag={H1_TAG}
            align="left"
            color="gray"
          >
            <FormattedMessage {...titleMessage} />
          </TitleUnderlined>
          <p className="description my-2">
            {MODAL_TYPES_DOWNLOAD.includes(formType)
              ? SUCCESS_FORM_MESSAGES[formType](intl.locale)
              : SUCCESS_FORM_MESSAGES[formType]}
          </p>
          <button
            className={classNames({
              "btn-base btn-outline-dark mt-4": true,
              "btn-sm": isMobile,
            })}
            onClick={() => navigate(-1)}
          >
            <FormattedMessage
              id="Button.goBack"
              description="Button come back text"
              defaultMessage="Come Back"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

ThankYou.propTypes = {
  msgId: PropTypes.string,
};

export default ThankYou;
