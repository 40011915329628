import { MODAL_TYPES_DOWNLOAD } from "components/RequestsSection/utils";

export const ENDPOINT_ZAP =
  "https://hooks.zapier.com/hooks/catch/9615379/3sz7ygh/";

export const handleURLPdfAccordingToFormType = ({ lang, formType }) => {
  const fileNames = {
    [MODAL_TYPES_DOWNLOAD[0]]: "digital-catalogue",
    [MODAL_TYPES_DOWNLOAD[1]]: "care_and_cleaning",
  };

  const urlPDFAccordingToFormType = {
    [MODAL_TYPES_DOWNLOAD[0]]:
      "https://landing-kizuna.s3.amazonaws.com/downloadable-files/digital_catalogue.pdf",
    [MODAL_TYPES_DOWNLOAD[1]]: `https://landing-kizuna.s3.amazonaws.com/downloadable-files/${lang}_care_and_cleaning.pdf`,
  };

  return {
    pdfURL: urlPDFAccordingToFormType[formType],
    fileName: fileNames[formType],
  };
};
