import { RiSearch2Line } from "react-icons/ri";
import { GlassMagnifier } from "react-image-magnifiers";
import { FormattedMessage } from "react-intl";
import ProductSpecs from "components/Designs/components/ProductSpecs";
import SampleCard from "../SampleCard";
import usePatternData from "./usePatternData";
import useSampleCard from "../SampleCard/useSampleCard";

const SamplesDetailForDesktop = ({
  state,
  design,
  handleSamplesData,
  handleShowInformationBox,
  handleClickInAddCatalogueToRequest,
}) => {
  const { pattern, color, reference, img, imgDetail, SAMPLES } = usePatternData(
    {
      state,
      design,
      handleSamplesData,
    }
  );
  const { handleAddSample } = useSampleCard({
    design,
    handleShowInfoBox: handleShowInformationBox,
  });

  return (
    <>
      <div className="d-lg-flex mt-1 mt-md-5 font-color">
        <div className="col-lg-5 sample-image mb-4">
          <div className="position-absolute p-4 view-icon">
            <div className="p-3 rounded-pill bg-light">
              <RiSearch2Line size={24} color="gray" />
            </div>
          </div>

          <GlassMagnifier
            className="glass-magnifier"
            imageSrc={imgDetail}
            imageAlt="image sample"
            magnifierBorderColor="white"
            magnifierBackgroundColor="white"
            magnifierBorderSize={2}
            magnifierOffsetX={0}
            magnifierOffsetY={0}
            magnifierSize={150}
          />
          <div className="d-flex justify-content-between align-items-center mt-2">
            <p className="reference-size fw-light font-color p-0 m-0">
              {`${color} | ${reference}`}
            </p>
            <button
              className="btn btn-secondary add-sample-button ms-2"
              onClick={() =>
                handleAddSample({ color, reference, img, imgDetail })
              }
            >
              <span className="label">
                <FormattedMessage
                  id="Kizuna.buttonAddA4Samples"
                  description="Add a4 samples"
                  defaultMessage="Add"
                />
              </span>
            </button>
          </div>
        </div>
        <div className="mt-5 mt-lg-0 ps-lg-5 col-lg-7 py-0 font-color">
          <h2 className="p-0 m-0 mb-4 text-capitalize">{pattern}</h2>
          <ProductSpecs />
        </div>
      </div>
      <div className="d-flex flex-wrap mt-4 justify-content-center justify-content-md-start">
        {SAMPLES.map((sample) => (
          <SampleCard
            key={`key_${sample.reference}_${sample.color}`}
            design={design}
            handleShowInfoBox={handleShowInformationBox}
            sample={sample}
          />
        ))}
      </div>
      <p className="fs-4 fw-light text-capitalize text-center my-2">
        <FormattedMessage id="Kizuna.or" defaultMessage="Or" />
      </p>
      <div className="d-flex justify-content-center">
        <button
          type="button"
          className="btn-base btn-secondary px-md-5"
          onClick={handleClickInAddCatalogueToRequest}
        >
          <FormattedMessage
            id="Kizuna.buttonAddCatalogue"
            description="order samples"
            defaultMessage="ADD CATALOGUE"
          />
        </button>
      </div>
    </>
  );
};

export default SamplesDetailForDesktop;
