import { useState, useEffect } from "react";
import { GEOLOCATION_ENDPOIND, LANGUAGES_BY_COUNTRY_CODES } from "consts";
import { getCurrentLanguage } from "services/instances";

const useLanguage = () => {
  const langByUrl = getCurrentLanguage();
  const [lang, setLang] = useState();

  useEffect(() => {
    const fetchLanguage = async () => {
      try {
        if (!langByUrl) {
          const language = (await getCountryCodeByIp()) || "en";
          setLang(language);
        } else {
          setLang(langByUrl);
        }
      } catch (error) {
        console.error("Error fetching language:", error);
      }
    };

    fetchLanguage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCountryCodeByIp = () =>
    fetch(GEOLOCATION_ENDPOIND)
      .then((response) => response.json())
      .then((data) => {
        if (LANGUAGES_BY_COUNTRY_CODES[data.country_code]) {
          return LANGUAGES_BY_COUNTRY_CODES[data.country_code];
        }
      });

  return { lang };
};

export default useLanguage;
