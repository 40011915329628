import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ConnectedIntlProvider from "intl/ConnectedIntlProvider";
import ProductDetailsPage from "modules/ProductDetailsPage";
import Home from "modules/home";
import KizunaCollections from "modules/kizunaCollectionsPage";
import NotFound from "modules/not-found";
import Spinner from "components/Spinner";
import useLanguage from "hooks/useLanguage";
import Analytics from "components/Analytics";
import { GTAG_BY_LANG, HOTJAR_BY_LANG } from "consts";
import ThankYou from "modules/thank-you";
// import ComingSoonPage from "modules/ComingSoonPage";
// import Detail from "modules/detail";
// import Sandbox from "modules/sandbox";

function RouteManager() {
  const { lang } = useLanguage();

  return (
    <>
      {!lang ? (
        <Spinner />
      ) : (
        <ConnectedIntlProvider locale={lang}>
          <>
            <Analytics
              gtag={GTAG_BY_LANG[lang]}
              hotjarInfo={HOTJAR_BY_LANG[lang]}
            />
            <BrowserRouter>
              <Routes>
                <Route
                  path="/"
                  element={<Navigate to={lang} replace={true} />}
                />
                <Route path="/:lang" element={<Home />} />
                <Route path="/:lang/:design" element={<ProductDetailsPage />} />
                <Route path="/:lang/about" element={<KizunaCollections />} />
                <Route path="/:lang/thank-you/:form" element={<ThankYou />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </BrowserRouter>
          </>
        </ConnectedIntlProvider>
      )}
    </>
  );
}

export default RouteManager;
