import { ApolloProvider } from "@apollo/client";
import client from "api";
import { LanguageProvider } from "context/languageContext";
import { StateMachineProvider } from "little-state-machine";
import RouteManager from "routes";
import { storeWithLittleStateMachine } from "./store";

function LandingKizuna() {
  storeWithLittleStateMachine();

  return (
    <ApolloProvider client={client}>
      <StateMachineProvider>
        <LanguageProvider>
          <RouteManager />
        </LanguageProvider>
      </StateMachineProvider>
    </ApolloProvider>
  );
}

export default LandingKizuna;
