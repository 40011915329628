export const LANGUAGES = ["es", "en", "it", "fr", "de", "pl"];

export const LANGUAGES_BY_COUNTRY_CODES = {
  AD: "es", // Andorra
  AL: "en", // Albania
  AM: "en", // Armenia
  AT: "de", // Austria
  AZ: "en", // Azerbaijan
  BH: "en", // Bahrain
  BD: "en", // Bangladesh
  BE: "en", // Belgium
  BT: "en", // Bhutan
  BA: "en", // Bosnia
  BN: "en", // Brunei
  BG: "en", // Bulgaria
  KH: "en", // Cambodia
  HR: "en", // Croatia
  CY: "en", // Cyprus
  CZ: "en", // Czech
  DK: "en", // Denmark
  EE: "en", // Estonia
  FI: "en", // Finland
  FR: "fr", // France
  GE: "en", // Georgia
  DE: "de", // Germany
  GR: "en", // Greece
  HK: "en", // HongKong
  HU: "en", // Hungary
  IS: "en", // Iceland
  IN: "en", // India
  ID: "en", // Indonesia
  IR: "en", // Iran
  IQ: "en", // Iraq
  IE: "en", // Ireland
  IL: "en", // Israel
  IT: "it", // Italy
  JP: "en", // Japan
  JO: "en", // Jordan
  KZ: "en", // Kazakhstan
  KW: "en", // Kuwait
  KG: "en", // Kyrgyzstan
  LA: "en", // Laos
  LV: "en", // Latvia
  LB: "en", // Lebanon
  LI: "en", // Liechtenstein
  LT: "en", // Lithuania
  LU: "en", // Luxembourg
  MO: "en", // Macau
  MK: "en", // Macedonia
  MY: "en", // Malaysia
  MT: "en", // Malta
  MD: "en", // Moldova
  MC: "en", // Monaco
  MN: "en", // Mongolia
  ME: "en", // Montenegro
  MM: "en", // Myanmar
  NP: "en", // Nepal
  NL: "en", // Netherlands
  NZ: "en", // NewZealand
  NO: "en", // Norway
  OM: "en", // Oman
  PK: "en", // Pakistan
  PS: "en", // Palestine
  PH: "en", // Philippines
  PL: "pl", // Poland
  PT: "en", // Portugal
  QA: "en", // Qatar
  RO: "en", // Romania
  RU: "en", // Russia
  SM: "en", // SanMarino
  SA: "en", // SaudiArabia
  RS: "en", // Serbia
  SG: "en", // Singapore
  SK: "en", // Slovakia
  SI: "en", // Slovenia
  KR: "en", // SouthKorea
  ES: "es", // Spain
  LK: "en", // SriLanka
  SE: "en", // Sweden
  CH: "en", // Switzerland
  SY: "en", // Syria
  TW: "en", // Taiwan
  TJ: "en", // Tajikistan
  TH: "en", // Thailand
  TR: "en", // Turkey
  TM: "en", // Turkmenistan
  UA: "en", // Ukraine
  AE: "en", // UAE
  GB: "en", // UK
  US: "en", // USA
  UZ: "en", // Uzbekistan
  VN: "en", // Vietnam
};

export const GEOLOCATION_ENDPOIND = `https://ipgeolocation.abstractapi.com/v1/?api_key=${process.env.REACT_APP_ABSTRACT_API_API_KEY}`;

export const GTAG_BY_LANG = {
  de: "GTM-PTVZSKLF",
  en: "GTM-PQXHH454",
  es: "GTM-P2RWC9PC",
  fr: "GTM-KVV2BVPM",
  it: "GTM-NT479N84",
  pl: "GTM-MFX9SHG9",
};

export const HOTJAR_BY_LANG = {
  de: null,
  en: null,
  es: { hjid: 3804212, hjsv: 6 },
  fr: null,
  it: null,
  pl: null,
};
