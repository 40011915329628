import { KIZUNA_DESIGNS } from "components/Designs/constants";
import { FormattedMessage } from "react-intl";
import { SAMPLES } from "./constants/samples";
import "./styles/ProductSamples.scss";
import { useStateMachine } from "little-state-machine";
import { updateAction } from "app/store";
import { NAV_ITEMS } from "components/Header/constants";
import { useNavigate } from "react-router-dom";
import { getCurrentLanguage } from "services/instances";

const ProductsSamples = () => {
  const navigate = useNavigate();
  const { actions } = useStateMachine({ updateAction });

  const handleClickOnColor = (design, sampleDetails) => {
    actions.updateAction({
      showUnderlined: {
        checker: design === KIZUNA_DESIGNS[0] ? true : false,
        artisan: design === KIZUNA_DESIGNS[1] ? true : false,
        tweed: design === KIZUNA_DESIGNS[2] ? true : false,
      },
      productDetails: {
        title: design,
        sampleDetails,
      },
    });

    navigate(`/${getCurrentLanguage()}/${design.toLowerCase()}`);
  };

  return (
    <div className="d-flex flex-column justify-content-center my-2 font-color fw-bold">
      <h1 className="title">
        <FormattedMessage
          id="Kizuna.kizunaDesigns"
          description="Kizuna Designs"
          defaultMessage="Kizuna Designs"
        />
      </h1>
      {SAMPLES.map(({ designGroup, colors }, index) => (
        <div
          className="d-flex flex-column justify-content-start mt-3 mt-md-4"
          id={NAV_ITEMS[index] === "section3" ? NAV_ITEMS[1] : NAV_ITEMS[index]}
          key={index}
        >
          <h2 className="design-title">{designGroup}</h2>
          <div className="d-flex flex-wrap justify-content-center justify-content-md-start">
            {colors.map(({ color, reference, img, imgDetail }, index) => {
              const sampleDetails = { color, reference, img, imgDetail };

              return (
                <div key={index} className="grow-on-hover my-1 mx-1 mx-md-3">
                  <div
                    className="overflow-hidden image-sample-size"
                    onClick={() =>
                      handleClickOnColor(designGroup, sampleDetails)
                    }
                  >
                    <img
                      className="position-image"
                      src={img}
                      alt=""
                      width="400%"
                    />
                  </div>
                  <span className="label fw-normal">{color}</span>
                  <span className="label label-ref fw-light">{reference}</span>
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductsSamples;
