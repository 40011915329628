import useMediaQuery from "hooks/useMediaQuery";
import ForDesktop from "./components/ForDesktop";
import { LG } from "consts/breakpoints";
import ForMobile from "./components/For Mobile";
import { KIZUNA_PATTERNS } from "./constants";
import "./styles/patterns-list.scss";

const PatternsList = () => {
  const isMobile = useMediaQuery(LG);

  return (
    <>
      {!isMobile ? (
        <ForDesktop patterns={KIZUNA_PATTERNS} />
      ) : (
        <ForMobile patterns={KIZUNA_PATTERNS} />
      )}
    </>
  );
};

export default PatternsList;
