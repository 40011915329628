import { FormattedMessage } from "react-intl";
import breathable from "./imgs/breathable.png";
import lightWheight from "./imgs/light-wheight.png";
import quickDry from "./imgs/quick-dry.png";
import reversible from "./imgs/reversible.png";
import staysCool from "./imgs/stays-cool.png";
import thermalStability from "./imgs/thermal-stability.png";
import washable from "./imgs/washable.png";
import waterRepelent from "./imgs/water-repelent.png";

export const FEATURES_AND_BENEFITS_ELEMENTS = [
  {
    img: waterRepelent,
    description: (
      <FormattedMessage
        id="Kizuna.featuresAndBenefits.waterRepelent"
        defaultMessage="Water Repellent"
      />
    ),
  },
  {
    img: reversible,
    description: (
      <FormattedMessage
        id="Kizuna.featuresAndBenefits.reversible"
        defaultMessage="Reversible"
      />
    ),
  },
  {
    img: breathable,
    description: (
      <FormattedMessage
        id="Kizuna.featuresAndBenefits.breathable"
        defaultMessage="Breathable"
      />
    ),
  },
  {
    img: quickDry,
    description: (
      <FormattedMessage
        id="Kizuna.featuresAndBenefits.quickDry"
        defaultMessage="Quick Dry"
      />
    ),
  },
  {
    img: thermalStability,
    description: (
      <FormattedMessage
        id="Kizuna.featuresAndBenefits.thermalStability"
        defaultMessage="Thermal stability"
      />
    ),
  },
  {
    img: staysCool,
    description: (
      <FormattedMessage
        id="Kizuna.featuresAndBenefits.staysCool"
        defaultMessage="Stays cool"
      />
    ),
  },
  {
    img: washable,
    description: (
      <FormattedMessage
        id="Kizuna.featuresAndBenefits.washable"
        defaultMessage="Washable"
      />
    ),
  },
  {
    img: lightWheight,
    description: (
      <FormattedMessage
        id="Kizuna.featuresAndBenefits.lightWeight"
        defaultMessage="Light weight"
      />
    ),
  },
];
