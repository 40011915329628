import { FormattedMessage } from "react-intl";
import Checker from "components/KizunaHistory/img/checker.jpg";
import Artisan from "components/KizunaHistory/img/artisan.jpg";
import Tweed from "components/KizunaHistory/img/tweed.jpg";

export const KIZUNA_PATTERNS = [
  {
    id: "checker",
    title: "checker",
    img: Checker,
    description: (
      <FormattedMessage
        id="Kizuna.historySectionChecker"
        defaultMessage="Immerse in nature-inspired rustic sensations through three colored fibers, interweaving linear perfection that defines norms, simplicity, and minimalistic excellence. Embrace the versatility of these modular chess design that adapts the upholstery configurations."
      />
    ),
  },
  {
    id: "artisan",
    title: "artisan",
    img: Artisan,
    description: (
      <FormattedMessage
        id="Kizuna.historySectionArtisan"
        defaultMessage="Drawing on the heritage of global artisans this design channels the very soul of age-old techniques, cherished across generations by highly skilled craftsmen. It pays homage to tradition; whilst also embracing modernity. The colour palette effortlessly blends vibrant and earthy tones, reminiscent of the natural dyes and pigments widely used by in traditional craftsmanship."
      />
    ),
  },
  {
    id: "tweed",
    title: "tweed",
    img: Tweed,
    description: (
      <FormattedMessage
        id="Kizuna.historySectionTweed"
        defaultMessage="An intricately designed pattern that unveils an array of colours and contrasts, encapsulating a spirit of diversity and uniqueness. Inspired by twill woven structure, it forms a robust and resilient fabric. This creation pays homage to the fabric's inbuilt heritage, whilst encapsulating its enduring grace and timeless allure."
      />
    ),
  },
];
