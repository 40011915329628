import PropTypes from "prop-types";
import { ErrorMessage as FormikErrorMessage } from "formik";

const ErrorMessage = ({ className, name, color = "red" }) => {
  return (
    <FormikErrorMessage
      className={`form-error-message form-error-message--color-${color} ${className}`}
      component="div"
      name={name}
    />
  );
};

ErrorMessage.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  color: PropTypes.oneOf(["red", "black", "white"]),
};

export default ErrorMessage;
