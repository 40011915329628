import { FormattedMessage, defineMessages } from "react-intl";
import {
  REQUEST_SAMPLES_INFO,
  REQUEST_CATALOGUE_INFO,
  CARE_AND_CLEANING_GUIDE_INFO,
  DIGITAL_CATALOGUE_INFO,
  CONTACT_INFO,
  MODAL_TYPES,
} from "components/RequestsSection/utils";
import { handleDownloadFile } from "modules/thank-you/utils";

const REQUEST_SUCCESS_MESSAGE = (
  <FormattedMessage
    id="SamplingRequest.successRequest"
    defaultMessage="We received your request and will be processing it soon!"
  />
);

export const SUCCESS_FORM_MESSAGES = {
  [REQUEST_SAMPLES_INFO.type]: REQUEST_SUCCESS_MESSAGE,
  [REQUEST_CATALOGUE_INFO.type]: REQUEST_SUCCESS_MESSAGE,
  [DIGITAL_CATALOGUE_INFO.type]: (lang) => (
    <FormattedMessage
      id="SamplingRequest.successDownload"
      defaultMessage="Thanks for your interest in Kizuna Please <a>click here</a> to download the PDF file"
      values={{
        sup: (...chunks) => <sup>{chunks}</sup>,
        a: (...chunks) => (
          <a
            id="pdfFileDownload"
            download="digital-catalogue.pdf"
            href="/#"
            onClick={(e) => {
              e.preventDefault();
              handleDownloadFile({
                lang,
                formType: MODAL_TYPES.DIGITAL_CATALOGUE,
              });
            }}
            rel="noopener noreferrer"
            className="pdf-link"
          >
            {chunks}
          </a>
        ),
      }}
    />
  ),
  [CARE_AND_CLEANING_GUIDE_INFO.type]: (lang) => (
    <FormattedMessage
      id="SamplingRequest.successDownload"
      defaultMessage="Thanks for your interest in Kizuna Please <a>click here</a> to download the PDF file"
      values={{
        a: (...chunks) => (
          <a
            id="pdfFileDownload"
            download={`care-cleaning-${lang}.pdf`}
            href="/#"
            onClick={(e) => {
              e.preventDefault();
              handleDownloadFile({
                lang,
                formType: MODAL_TYPES.CARE_AND_CLEANING_GUIDE,
              });
            }}
            rel="noopener noreferrer"
            className="pdf-link"
          >
            {chunks}
          </a>
        ),
      }}
    />
  ),
  [CONTACT_INFO.type]: (
    <FormattedMessage
      id="SamplingRequest.successContact"
      defaultMessage="Thanks for contacting us, we'll be in touch soon"
    />
  ),
};

export const EMAIL_MESSAGES = defineMessages({
  intro: {
    id: "email.intro",
    defaultMessage:
      "Your order has been submitted successfully. We will contact you shortly in order to process your request.",
  },
  summaryTitle: {
    id: "email.summaryTitle",
    defaultMessage: "Order summary",
  },
  product: {
    id: "email.product",
    defaultMessage: "Product",
  },
  customerDetails: {
    id: "email.customerDetails",
    defaultMessage: "Customer Details",
  },
  shippingInformation: {
    id: "email.shippingInformation",
    defaultMessage: "Shipping Information",
  },
  clientSubject: {
    id: "email.client.subject",
    defaultMessage: "Thanks for your interest in Kizuna",
  },
  adminSubject: {
    id: "email.admin.subject",
    defaultMessage: "You have a new Sample Request | Landing Kizuna",
  },
});
