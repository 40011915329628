import { useEffect, useState } from "react";
import { useStateMachine } from "little-state-machine";
import useToggle from "hooks/useToggle";
import { updateAction } from "app/store";

const KIZUNA_CATALOGUE_ITEM = { catalogue: "Kizuna Catalogue" };

const useRequestModal = () => {
  const [toggable, toggle] = useToggle();
  const { state, actions } = useStateMachine({ updateAction });
  const [modalContext, setModalContext] = useState({});

  const handleAddCatalogueToRequest = () => {
    const updateSamplesCount = state?.sampleCount
      ? [...state?.sampleCount, KIZUNA_CATALOGUE_ITEM]
      : [KIZUNA_CATALOGUE_ITEM];

    actions.updateAction({
      sampleCount: updateSamplesCount,
    });
  };

  const handleOrderCatalogue = (modalType) => {
    handleAddCatalogueToRequest();
    handleClickInRequest(modalType);
  };

  const handleClickInRequest = (modalType) => {
    setModalContext(modalType);
    toggle();
  };

  useEffect(() => {}, [modalContext]);

  return {
    modalContext,
    toggable,
    toggle,
    handleClickInRequest,
    handleOrderCatalogue,
  };
};
export default useRequestModal;
