export const getCurrentLanguage = () => {
  const { location } = window;
  const parts = location.pathname.split("/");
  const lang = parts[1];

  return lang;
};

export const getCurrentInstanceId = () => {
  const { location } = window;
  const parts = location.pathname.split("/");
  const instanceCode = parts[1].split("-")[1];

  switch (instanceCode) {
    case "la":
      return process.env.REACT_APP_LATAM_INSTANCE_ID;

    case "mx":
      return process.env.REACT_APP_MX_INSTANCE_ID;

    case "us":
      return process.env.REACT_APP_USA_INSTANCE_ID;

    case "eu":
      return process.env.REACT_APP_EU_INSTANCE_ID;

    case "sm":
      return process.env.REACT_APP_SM_INSTANCE_ID;

    default:
      return process.env.REACT_APP_LATAM_INSTANCE_ID;
  }
};

export const isUSA = () =>
  getCurrentInstanceId() === process.env.REACT_APP_USA_INSTANCE_ID;
export const isLATAM = () =>
  getCurrentInstanceId() === process.env.REACT_APP_LATAM_INSTANCE_ID;
export const isMEXICO = () =>
  getCurrentInstanceId() === process.env.REACT_APP_MX_INSTANCE_ID;
export const isEUROPE = () =>
  getCurrentInstanceId() === process.env.REACT_APP_EU_INSTANCE_ID;
export const isMARINE = () =>
  getCurrentInstanceId() === process.env.REACT_APP_SM_INSTANCE_ID;

export const getCurrentLanguageAndInstanceCode = (language, instanceCode) => {
  const path = window.location.pathname.split("/");
  let lang = language;
  let instance = instanceCode;
  const regexp = /^([a-z]{2})-([a-z]{2})$/;
  if (path.length >= 2) {
    path.shift(); // removes the first value which is an empty string
    const firstPart = path.shift(); // gets the first part of the path, which is supposed to be "es-la", for example
    if (regexp.test(firstPart)) {
      const matches = firstPart.match(regexp);
      lang = matches[1];
      instance = matches[2];
    }
  }
  return [lang, instance];
};
