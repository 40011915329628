import classNames from "classnames";
import { useEffect, useState } from "react";
import { Collapse } from "reactstrap";
import IconAccordion from "./components/IconAccordion";
import "./styles/SpecAccordion.scss";
import { useParams } from "react-router-dom";

const SpecAccordion = ({
  title,
  contentToShow,
  downloadIcon,
  onlyMobileStyle,
  id,
}) => {
  const { design } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => setIsOpen(!isOpen);

  useEffect(() => {
    if (design === id) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [design, id]);

  return (
    <div>
      <div className="accordion mt-1" id="accordion">
        <div
          className={classNames({
            "item-accordion": !onlyMobileStyle,
            "item-accordion-mobile": onlyMobileStyle && !isOpen,
          })}
        >
          <p
            className={classNames({
              "button-accordion w-100 p-0 text-secondary cursor-pointer fs-5": true,
              "fw-light": !isOpen,
              "font-bold": isOpen,
            })}
            onClick={handleClick}
          >
            {title}
            {(isOpen || !isOpen) && (
              <IconAccordion
                isDownloadIcon={downloadIcon}
                isOpen={isOpen}
                onlySpecs={onlyMobileStyle}
              />
            )}
          </p>
        </div>
        <Collapse isOpen={isOpen}>{contentToShow}</Collapse>
      </div>
    </div>
  );
};

export default SpecAccordion;
