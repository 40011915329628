import React from "react";
import { FormattedMessage } from "react-intl";
import { NAV_ITEMS } from "components/Header/constants";
import RequestsSection from "components/RequestsSection";
import useRequestModal from "components/Footer/useRequestModal";
import { REQUEST_SAMPLES_INFO } from "components/RequestsSection/utils";
import fabric from "./img/fabric.jpg";
import PatternsList from "./PatternsList";
import "./styles/KizunaStory.scss";
import classNames from "classnames";
import useMediaQuery from "hooks/useMediaQuery";
import { MD } from "consts/breakpoints";

const KizunaHistory = () => {
  const isMobile = useMediaQuery(MD);
  const { modalContext, toggable, toggle, handleOrderCatalogue } =
    useRequestModal();

  return (
    <>
      <div
        id={NAV_ITEMS[0]}
        className="d-lg-flex mt-5 py-md-2 section-container"
      >
        <div className="col-12 col-lg-7">
          <img className="fabric-img" src={fabric} alt="fabric" />
        </div>
        <div className="col-lg-5 py-lg-5">
          <div className="col-12 col-md-6 col-lg-12 border border-2 d-flex align-items-center p-4 p-md-5 bg-platinum description-container">
            <p
              className={classNames({
                "font-color": true,
                "text-size-small": isMobile,
              })}
            >
              <FormattedMessage
                id="Kizuna.historySectionKizunaDescription"
                defaultMessage="The Kizuna Collection, in essence is a testament to the power of cooperation and the unyielding spirit of togetherness. Just as the Japanese term {br} <span>Kizuna</span> emphasises standing together through challenges, the result of this skilful collaboration between Spradling<sup>®</sup> and Citel<sup>®</sup> showcases how our joint efforts push the boundaries of knowledge and creativity."
                values={{
                  sup: (...chunks) => <sup>{chunks}</sup>,
                  span: (...chunks) => <span>"{chunks}"</span>,
                }}
              />
            </p>
          </div>
        </div>
      </div>
      <div className="d-flex mt-md-5 px-3 px-md-5 mb-md-4 image-backgrond-inspired">
        <div className="col-12 d-flex align-items-center text-white">
          <div className="px-lg-5">
            <h1
              className={classNames({
                "fs-3 my-3": isMobile,
              })}
            >
              <FormattedMessage
                id="Kizuna.historySectionInspired"
                defaultMessage="Inspired by the {br} Wabi-Sabi concept"
                values={{
                  br: <br />,
                }}
              />
            </h1>
            <p
              className={classNames({
                "text-size-small": isMobile,
              })}
            >
              <FormattedMessage
                id="Kizuna.historySectionInspiredDescription"
                defaultMessage="This collection celebrates the beauty of {br} imperfection and impermanence, embracing the {br} beauty of the natural world and appreciating the {br} irregularities and asymmetry found in nature."
                values={{
                  br: <br />,
                }}
              />
            </p>
          </div>
        </div>
      </div>
      <PatternsList />
      <div className="text-center py-2 py-md-5">
        <button
          type="button"
          className={classNames({
            "btn-base btn-secondary px-md-5 mb-2 mb-md-0": true,
            "btn-sm": isMobile,
          })}
          onClick={() => handleOrderCatalogue(REQUEST_SAMPLES_INFO)}
        >
          <FormattedMessage
            id="Kizuna.buttonOrderKizunaCatalogue"
            description="order samples"
            defaultMessage="Order Kizuna Catalogue Here!"
          />
        </button>
      </div>
      <div
        id={NAV_ITEMS[2]}
        className="d-lg-flex section-container my-2 p-3 p-md-5 spirit-brackground"
      >
        <div className="p-lg-5 col-12 d-flex align-items-start">
          <div className="text-spirit-container">
            <h2
              className={classNames({
                "my-3 mb-md-4": true,
                "fs-3": isMobile,
              })}
            >
              <FormattedMessage
                id="Kizuna.historySectionTheTrueSpiritKizuna.title"
                defaultMessage="The true spirit of the <span>Kizuna</span>"
              />
            </h2>
            <p
              className={classNames({
                "col-12 col-md-6 col-lg-4": true,
                "text-size-small": isMobile,
              })}
            >
              <FormattedMessage
                id="Kizuna.historySectionTheTrueSpiritKizuna.description"
                defaultMessage="Resonates throughout the Kizuna Collection, bringing forth a celebration of human connections bonds, and relationships that extend far beyond our own business. It becomes a symbol of unity, trust, and {br} solidarity. {br} {br} A reminder that we can achieve remarkable feats together and create something extraordinary, reflecting the strength and resilence of working collaboratively toward a common goal."
                values={{
                  span: (...chunks) => (
                    <span className="font-dala-moa">"{chunks}"</span>
                  ),
                  br: <br />,
                }}
              />
            </p>
          </div>
        </div>
      </div>
      <RequestsSection
        modalContext={modalContext}
        toggable={toggable}
        toggle={toggle}
      />
    </>
  );
};

export default KizunaHistory;
