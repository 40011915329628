import { FormattedMessage } from "react-intl";
import ProductSpecs from "components/Designs/components/ProductSpecs";
import SampleSliderList from "../SampleSliderList";
import usePatternData from "../SamplesDetailForDesktop/usePatternData";
import useSampleCard from "../SampleCard/useSampleCard";

const SamplesDetailForMobile = ({
  state,
  design,
  handleSamplesData,
  handleShowInformationBox,
  handleClickInAddCatalogueToRequest,
}) => {
  const { color, reference, img, imgDetail, SAMPLES } = usePatternData({
    state,
    design,
    handleSamplesData,
  });
  const { handleAddSample } = useSampleCard({
    design,
    handleShowInfoBox: handleShowInformationBox,
  });

  return (
    <>
      <div className="d-lg-flex mt-1 mt-md-5 font-color justify-content-center">
        <div className="col-lg-8 col-xl-12 sample-image mb-4">
          <img
            src={imgDetail}
            alt="Sample-details"
            className="h-100 w-100 sample-image"
          />
          <div className="d-flex justify-content-between align-items-center mt-2">
            <p className="reference-size fw-light font-color p-0 m-0 align-self-start">
              {`${color} | ${reference}`}
            </p>
            <button
              className="btn btn-secondary add-sample-button ms-2 align-self-end"
              onClick={() =>
                handleAddSample({ color, reference, img, imgDetail })
              }
            >
              <span className="label">
                <FormattedMessage
                  id="Kizuna.buttonAddA4Samples"
                  description="Add a4 samples"
                  defaultMessage="Add"
                />
              </span>
            </button>
          </div>
        </div>
      </div>
      <SampleSliderList
        design={design}
        samples={SAMPLES}
        handleShowInfoBox={handleShowInformationBox}
      />
      <p className="fw-light text-capitalize text-center my-1">
        <FormattedMessage id="Kizuna.or" defaultMessage="Or" />
      </p>
      <div className="d-flex justify-content-center mb-4">
        <button
          type="button"
          className="btn-base btn-secondary btn-sm px-md-5"
          onClick={handleClickInAddCatalogueToRequest}
        >
          <FormattedMessage
            id="Kizuna.buttonAddCatalogue"
            description="order samples"
            defaultMessage="ADD CATALOGUE"
          />
        </button>
      </div>
      <ProductSpecs />
    </>
  );
};

export default SamplesDetailForMobile;
