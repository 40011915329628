import SamplingRequest from "components/SamplingRequest";
import ModalContext from "components/SamplingRequest/context";
import PropTypes from "prop-types";
import "./styles/requests-section.scss";

function RequestsSection({ modalContext, toggable, toggle }) {

  return (
    <>
      <ModalContext.Provider value={modalContext ?? ''}>
        <SamplingRequest isOpen={toggable} toggle={toggle} />
      </ModalContext.Provider>
    </>
  );
}

RequestsSection.propTypes = {
  modalContext: PropTypes.object,
  toggable: PropTypes.bool,
  toggle: PropTypes.func
};

export default RequestsSection;
