import React, { useEffect } from "react";
import { Field, useFormikContext } from "formik";
import Select from "react-select";
import { FormattedMessage } from "react-intl";
import { useStateMachine } from "little-state-machine";
import { updateAction } from "app/store";
import { handleAddedSamplesByTheUser } from "./utils";
import "./styles/colors-select.scss";

const ColorsSelect = ({ name, id }) => {
  const { state, actions } = useStateMachine({ updateAction });
  const formik = useFormikContext();
  const isDisabled = formik.values[name] && formik.values[name].length >= 10;
  const SELECTED_COLORS = handleAddedSamplesByTheUser(state?.sampleCount);

  const handleChange = (selected) => {
    let tempArray = [];

    tempArray = selected.map(({ data }) => ({
      pattern: data.pattern,
      color: data.color,
      reference: data.reference,
      catalogue: data?.catalogue,
    }));

    actions.updateAction({
      sampleCount: tempArray,
    });

    formik.setFieldValue(name, selected);
  };

  useEffect(() => {
    if (SELECTED_COLORS && SELECTED_COLORS.length) {
      handleChange(SELECTED_COLORS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Field id={id} name={name}>
      {({ field }) => {
        return (
          <Select
            id={id}
            name={name}
            isMulti
            isDisabled={isDisabled}
            value={field.values}
            defaultValue={SELECTED_COLORS}
            onChange={handleChange}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                border:
                  (state.isFocused || !state.isFocused) && "2px solid #bdbdbd",
                ":hover": {
                  borderColor: "gray",
                },
                boxShadow: "none",
                borderRadius: "none",
                padding: "2px 0px",
              }),
            }}
            placeholder={
              <p className="p-0 m-0 fw-light fs-5 select-color">
                <FormattedMessage
                  id="SamplingRequest.labelColour"
                  defaultMessage="Colour"
                />
              </p>
            }
          />
        );
      }}
    </Field>
  );
};

export default ColorsSelect;
