const ForMobile = ({ patterns }) => (
  <section className="mt-5">
    {patterns.map(({ id, title, description, img }) => (
      <div key={`patterns-for-desktop-${id}`} className="pb-2">
        <div className="position-relative">
          <img className="pattern-img" src={img} alt={title} />
          <div className="title-container upholstery-glassmorphism">
            <h2 className="fs-3 text-capitalize fw-bold text-light m-0 p-0">
              {title}
            </h2>
          </div>
        </div>
        <p className="text-size-small font-color my-4 mx-3">{description}</p>
      </div>
    ))}
  </section>
);

export default ForMobile;
