import Slider from "components/Carousel";
import ProductsSamples from "components/Designs/components/ProductsSamples";
import Footer from "components/Footer";
import Header from "components/Header";
import { NAV_ITEMS } from "components/Header/constants";
import Hero from "components/Hero";
import { SLIDES_DESKTOP, SLIDES_MOBILE, MAIN_VIDEO_URL } from "./constants";
import { FormattedMessage } from "react-intl";
import RequestsSection from "components/RequestsSection";
import { REQUEST_SAMPLES_INFO } from "components/RequestsSection/utils";
import classNames from "classnames";
import { MD } from "consts/breakpoints";
import useMediaQuery from "hooks/useMediaQuery";
import useRequestModal from "components/Footer/useRequestModal";
import { useStateMachine } from "little-state-machine";
import SamplesCount from "components/Designs/components/ProductsSamplesDetails/components/SamplesCount";

const Home = () => {
  const { state } = useStateMachine();
  const {
    modalContext,
    toggable,
    toggle,
    handleClickInRequest,
    handleOrderCatalogue,
  } = useRequestModal();
  const isMobile = useMediaQuery(MD);
  const slides = !isMobile ? SLIDES_DESKTOP : SLIDES_MOBILE;

  return (
    <div>
      <Header />
      <Hero bannerVideo={MAIN_VIDEO_URL} />
      <div className="container" id={NAV_ITEMS[0]}>
        <ProductsSamples />
        <div className="d-block text-center my-4">
          <button
            type="button"
            className={classNames({
              "btn-base btn-secondary mt-md-3 px-md-5": true,
              "btn-sm": isMobile,
            })}
            onClick={() => handleOrderCatalogue(REQUEST_SAMPLES_INFO)}
          >
            <FormattedMessage
              id="Kizuna.buttonOrderKizunaCatalogue"
              description="order samples"
              defaultMessage="Order Kizuna Catalogue Here!"
            />
          </button>
        </div>
      </div>
      <Slider slides={slides} />
      <Footer />

      {!state?.sampleCount?.length ? (
        <></>
      ) : (
        <div className="position-relative">
          <SamplesCount
            toggle={() => handleClickInRequest(REQUEST_SAMPLES_INFO)}
          />
        </div>
      )}
      <RequestsSection
        modalContext={modalContext}
        toggable={toggable}
        toggle={toggle}
      />
    </div>
  );
};

export default Home;
