import { useState } from "react";
import PropTypes from "prop-types";
import { useStateMachine } from "little-state-machine";
import RequestsSection from "components/RequestsSection";
import { REQUEST_SAMPLES_INFO } from "components/RequestsSection/utils";
import SamplesCount from "./components/SamplesCount";
import SamplesDetailForDesktop from "./components/SamplesDetailForDesktop";
import SamplesDetailForMobile from "./components/SamplesDetailForMobile";
import { SAMPLES } from "../ProductsSamples/constants/samples";
import { updateAction } from "app/store";
import useToggle from "hooks/useToggle";
import useMediaQuery from "hooks/useMediaQuery";
import { LG } from "consts/breakpoints";
import "./styles/ProductsSamplesDetails.scss";

const KIZUNA_CATALOGUE_ITEM = { catalogue: "Kizuna Catalogue" };

const ProductsSamplesDetails = ({ design }) => {
  const isMobile = useMediaQuery(LG);
  const { state, actions } = useStateMachine({ updateAction });
  const [showInformationBox, setShowInformationBox] = useState(false);
  const [toggable, toggle] = useToggle();

  const handleShowInformationBox = () => {
    setShowInformationBox(true);
    setTimeout(() => {
      setShowInformationBox(false);
    }, 800);
  };

  const handleClickInAddCatalogueToRequest = () => {
    const updateSamplesCount = state?.sampleCount
      ? [...state?.sampleCount, KIZUNA_CATALOGUE_ITEM]
      : [KIZUNA_CATALOGUE_ITEM];

    actions.updateAction({
      sampleCount: updateSamplesCount,
    });
  };

  const handleSamplesData = (design) => {
    const samplesData = SAMPLES.map(({ designGroup, colors }) => {
      const isSameDesign =
        design === designGroup.toLowerCase() &&
        colors.map(({ color, reference, img, imgDetail }) => {
          const sample = {
            color,
            reference,
            img,
            imgDetail,
          };

          return sample;
        });

      return isSameDesign;
    }).filter((value) => value);

    return samplesData[0];
  };

  return (
    <div id="designs" className="mt-0 mb-5">
      {!isMobile ? (
        <SamplesDetailForDesktop
          state={state}
          design={design}
          handleSamplesData={handleSamplesData}
          handleShowInformationBox={handleShowInformationBox}
          handleClickInAddCatalogueToRequest={
            handleClickInAddCatalogueToRequest
          }
        />
      ) : (
        <SamplesDetailForMobile
          state={state}
          design={design}
          handleSamplesData={handleSamplesData}
          handleShowInformationBox={handleShowInformationBox}
          handleClickInAddCatalogueToRequest={
            handleClickInAddCatalogueToRequest
          }
        />
      )}

      {!state?.sampleCount?.length ? (
        <></>
      ) : (
        <div className="position-relative">
          <SamplesCount showBox={showInformationBox} toggle={toggle} />
        </div>
      )}

      <RequestsSection
        modalContext={REQUEST_SAMPLES_INFO}
        toggable={toggable}
        toggle={toggle}
      />
    </div>
  );
};

ProductsSamplesDetails.propTypes = {
  design: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setDesign: PropTypes.func,
};

export default ProductsSamplesDetails;
