import { useEffect, useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import Vimeo from "@u-wave/react-vimeo";
import { RiArrowDownSLine } from "react-icons/ri";
import { HiOutlinePlayCircle } from "react-icons/hi2";
import "./styles/video-vimeo.scss";

const VimeoVideo = ({ id, className, video, isAutoPlay = true }) => {
  const [player, setPlayer] = useState();
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (player) {
      player.on("play", () => {
        setIsPlaying(true);
      });
    }
  }, [player]);

  const handlePlay = () => {
    if (player) player.play();
  };

  return (
    <div
      id={id}
      className={classNames({
        "video-container": !isAutoPlay,
        "skeleton-background": !isPlaying && isAutoPlay,
        [className]: className !== undefined,
      })}
    >
      {!isAutoPlay && !isPlaying && (
        <HiOutlinePlayCircle
          className="play-icon"
          onClick={() => handlePlay()}
        />
      )}
      <Vimeo
        video={video}
        autoplay={isAutoPlay}
        controls={false}
        responsive={true}
        loop={true}
        background={isAutoPlay}
        quality="auto"
        onReady={(player) => {
          setPlayer(player);
        }}
      />
      {!isAutoPlay && (
        <div className="down-line-container">
          <RiArrowDownSLine className="down-line-icon scroll-animation" />
        </div>
      )}
    </div>
  );
};

VimeoVideo.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  video: PropTypes.string.isRequired,
  isAutoPlay: PropTypes.bool,
};

export default VimeoVideo;
