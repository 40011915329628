import { ARTISAN_IMAGES, CHECKER_IMAGES, TWEED_IMAGES } from "./samplesImages";

const CHECKER = "Checker";
const ARTISAN = "Artisan";
const TWEED = "Tweed";

export const SAMPLES = [
  {
    designGroup: CHECKER,
    colors: [
      {
        color: "WINTER",
        reference: "CHK - B013",
        img: CHECKER_IMAGES.WINTER,
        imgDetail: CHECKER_IMAGES.WINTER_B,
      },
      {
        color: "CREAMY",
        reference: "CHK - B014",
        img: CHECKER_IMAGES.CREAMY,
        imgDetail: CHECKER_IMAGES.CREAMY_B,
      },
      {
        color: "OVERCAST",
        reference: "CHK - B005",
        img: CHECKER_IMAGES.OVERCAST,
        imgDetail: CHECKER_IMAGES.OVERCAST_B,
      },
      {
        color: "ACQUA",
        reference: "CHK - B008",
        img: CHECKER_IMAGES.ACQUA,
        imgDetail: CHECKER_IMAGES.ACQUA_B,
      },
      {
        color: "CEDRUS",
        reference: "CHK - B007",
        img: CHECKER_IMAGES.CEDRUS,
        imgDetail: CHECKER_IMAGES.CEDRUS_B,
      },
      {
        color: "CARDAMOM",
        reference: "CHK - B004",
        img: CHECKER_IMAGES.CARDAMOM,
        imgDetail: CHECKER_IMAGES.CARDAMOM_B,
      },
      {
        color: "CRYSTAL",
        reference: "CHK - G010",
        img: CHECKER_IMAGES.CRYSTAL,
        imgDetail: CHECKER_IMAGES.CRYSTAL_B,
      },
      {
        color: "KSAMIL",
        reference: "CHK - G009",
        img: CHECKER_IMAGES.KSAMIL,
        imgDetail: CHECKER_IMAGES.KSAMIL_B,
      },
      {
        color: "SALVIA",
        reference: "CHK - N012",
        img: CHECKER_IMAGES.SALVIA,
        imgDetail: CHECKER_IMAGES.SALVIA_B,
      },
      {
        color: "BOBCAT",
        reference: "CHK - B003",
        img: CHECKER_IMAGES.BOBCAT,
        imgDetail: CHECKER_IMAGES.BOBCAT_B,
      },
      {
        color: "ATLANTIC",
        reference: "CHK - N010",
        img: CHECKER_IMAGES.ATLANTIC,
        imgDetail: CHECKER_IMAGES.ATLANTIC_B,
      },
      {
        color: "EMERALD",
        reference: "CHK - N009",
        img: CHECKER_IMAGES.EMERALD,
        imgDetail: CHECKER_IMAGES.EMERALD_B,
      },
      {
        color: "KHAKI",
        reference: "CHK - G003",
        img: CHECKER_IMAGES.KHAKI,
        imgDetail: CHECKER_IMAGES.KHAKI_B,
      },
      {
        color: "STORMY",
        reference: "CHK - G001",
        img: CHECKER_IMAGES.STORMY,
        imgDetail: CHECKER_IMAGES.STORMY_B,
      },
      {
        color: "SOOT",
        reference: "CHK - N002",
        img: CHECKER_IMAGES.SOOT,
        imgDetail: CHECKER_IMAGES.SOOT_B,
      },
    ],
  },
  {
    designGroup: ARTISAN,
    colors: [
      {
        color: "CELESTE",
        reference: "ART - B010",
        img: ARTISAN_IMAGES.CELESTE,
        imgDetail: ARTISAN_IMAGES.CELESTE_B,
      },
      {
        color: "SEAROCK",
        reference: "ART - G010",
        img: ARTISAN_IMAGES.SEAROCK,
        imgDetail: ARTISAN_IMAGES.SEAROCK_B,
      },
      {
        color: "SEAFOAM",
        reference: "ART - B008",
        img: ARTISAN_IMAGES.SEAFOAM,
        imgDetail: ARTISAN_IMAGES.SEAFOAM_B,
      },
      {
        color: "LEADEN",
        reference: "ART - B015",
        img: ARTISAN_IMAGES.LEADEN,
        imgDetail: ARTISAN_IMAGES.LEADEN_B,
      },
      {
        color: "MINT",
        reference: "ART - B007",
        img: ARTISAN_IMAGES.MINT,
        imgDetail: ARTISAN_IMAGES.MINT_B,
      },
      {
        color: "BARK",
        reference: "ART - N014",
        img: ARTISAN_IMAGES.BARK,
        imgDetail: ARTISAN_IMAGES.BARK_B,
      },
      {
        color: "WHEAT",
        reference: "ART - G014",
        img: ARTISAN_IMAGES.WHEAT,
        imgDetail: ARTISAN_IMAGES.WHEAT_B,
      },
      {
        color: "TWILIGHT",
        reference: "ART - G002",
        img: ARTISAN_IMAGES.TWILIGHT,
        imgDetail: ARTISAN_IMAGES.TWILIGHT_B,
      },
    ],
  },
  {
    designGroup: TWEED,
    colors: [
      {
        color: "SNOWY",
        reference: "TWD - B013",
        img: TWEED_IMAGES.SNOWY,
        imgDetail: TWEED_IMAGES.SNOWY_B,
      },
      {
        color: "OAT",
        reference: "TWD - B014",
        img: TWEED_IMAGES.OAT,
        imgDetail: TWEED_IMAGES.OAT_B,
      },
      {
        color: "BUFF",
        reference: "TWD - G006",
        img: TWEED_IMAGES.BUFF,
        imgDetail: TWEED_IMAGES.BUFF_B,
      },
      {
        color: "ASHY",
        reference: "TWD - G013",
        img: TWEED_IMAGES.ASHY,
        imgDetail: TWEED_IMAGES.ASHY_B,
      },
      {
        color: "FOGGY",
        reference: "TWD - B016",
        img: TWEED_IMAGES.FOGGY,
        imgDetail: TWEED_IMAGES.FOGGY_B,
      },
      {
        color: "OTTER",
        reference: "TWD - B003",
        img: TWEED_IMAGES.OTTER,
        imgDetail: TWEED_IMAGES.OTTER_B,
      },
      {
        color: "MANGROVE",
        reference: "TWD - N004",
        img: TWEED_IMAGES.MANGROVE,
        imgDetail: TWEED_IMAGES.MANGROVE_B,
      },

      {
        color: "CEDAR",
        reference: "TWD - G003",
        img: TWEED_IMAGES.CEDAR,
        imgDetail: TWEED_IMAGES.CEDAR_B,
      },
      {
        color: "DARKEST",
        reference: "TWD - G002",
        img: TWEED_IMAGES.DARKEST,
        imgDetail: TWEED_IMAGES.DARKEST_B,
      },
      {
        color: "ZEBRA",
        reference: "TWD - B001",
        img: TWEED_IMAGES.ZEBRA,
        imgDetail: TWEED_IMAGES.ZEBRA_B,
      },
      {
        color: "CAPRI",
        reference: "TWD - N010",
        img: TWEED_IMAGES.CAPRI,
        imgDetail: TWEED_IMAGES.CAPRI_B,
      },
      {
        color: "JANUBIO",
        reference: "TWD - N008",
        img: TWEED_IMAGES.JANUBIO,
        imgDetail: TWEED_IMAGES.JANUBIO_B,
      },
    ],
  },
];
