import classNames from "classnames";

const ForDesktop = ({ patterns }) =>
  patterns.map(({ id, title, description, img }, i) => (
    <div key={`patterns-for-desktop-${id}`} className="d-lg-flex py-3">
      <div
        className={classNames({
          "col-7": true,
          "order-2": i % 2,
        })}
      >
        <img className="pattern-img" src={img} alt={title} />
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <div
          className={classNames({
            "mx-5": true,
            "text-end": i % 2,
          })}
        >
          <h2 className="text-capitalize fw-bold font-color">{title}</h2>
          <p className="font-color">{description}</p>
        </div>
      </div>
    </div>
  ));

export default ForDesktop;
