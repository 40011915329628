import Slider from "react-slick";
import "components/Banner/components/HomeSlide/styles/home-slide.scss";
import SampleCard from "../SampleCard";

export const SLIDER_OPTIONS = {
  arrows: false,
  dots: true,
  infinite: true,
  lazyLoad: true,
  slidesToShow: 4,
  slidesToScroll: 4,
};

const SampleSliderList = ({ design, samples, handleShowInfoBox }) => (
  <Slider {...SLIDER_OPTIONS} className="my-4 pt-4">
    {samples.map((sample) => (
      <SampleCard
        key={`key_${sample.reference}_${sample.color}`}
        design={design}
        handleShowInfoBox={handleShowInfoBox}
        sample={sample}
      />
    ))}
  </Slider>
);

export default SampleSliderList;
