import { updateAction } from "app/store";
import SpecAccordion from "components/Designs/components/ProductSpecs/components/SpecAccordion";
import { SAMPLES } from "components/Designs/components/ProductsSamples/constants/samples";
import ProductsSamplesDetails from "components/Designs/components/ProductsSamplesDetails";
import { KIZUNA_DESIGNS } from "components/Designs/constants";
import { useStateMachine } from "little-state-machine";
import { useNavigate } from "react-router-dom";
import { getCurrentLanguage } from "services/instances";

const ProductDetailsInMobile = ({ design }) => {
  const navigate = useNavigate();
  const { actions } = useStateMachine({ updateAction });

  const handleClickInSomeDesign = (newDesignToShow) => {
    const sampleDetails = SAMPLES.filter(
      (designDetails) => designDetails.designGroup === newDesignToShow
    )[0].colors[0];

    if (newDesignToShow.toLowerCase() !== design) {
      actions.updateAction({
        productDetails: {
          title: newDesignToShow,
          sampleDetails,
        },
      });
    }

    navigate(`/${getCurrentLanguage()}/${newDesignToShow.toLowerCase()}`);
  };

  return (
    <div className="container my-4 my-md-5">
      <div onClick={() => handleClickInSomeDesign(KIZUNA_DESIGNS[0])}>
        <SpecAccordion
          title={
            <h4 className="design-title fw-bold d-inline p-2 p-md-4">
              {KIZUNA_DESIGNS[0]}
            </h4>
          }
          contentToShow={
            <ProductsSamplesDetails design={KIZUNA_DESIGNS[0].toLowerCase()} />
          }
          id={KIZUNA_DESIGNS[0].toLowerCase()}
          onlyMobileStyle
        />
      </div>
      <div onClick={() => handleClickInSomeDesign(KIZUNA_DESIGNS[1])}>
        <SpecAccordion
          title={
            <h4 className="design-title fw-bold d-inline p-2 p-md-4">
              {KIZUNA_DESIGNS[1]}
            </h4>
          }
          contentToShow={
            <ProductsSamplesDetails design={KIZUNA_DESIGNS[1].toLowerCase()} />
          }
          id={KIZUNA_DESIGNS[1].toLowerCase()}
          onlyMobileStyle
        />
      </div>
      <div onClick={() => handleClickInSomeDesign(KIZUNA_DESIGNS[2])}>
        <SpecAccordion
          title={
            <h4 className="design-title fw-bold d-inline p-2 p-md-4">
              {KIZUNA_DESIGNS[2]}
            </h4>
          }
          contentToShow={
            <ProductsSamplesDetails design={KIZUNA_DESIGNS[2].toLowerCase()} />
          }
          id={KIZUNA_DESIGNS[2].toLowerCase()}
          onlyMobileStyle
        />
      </div>
    </div>
  );
};

export default ProductDetailsInMobile;
